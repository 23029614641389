<script>
  import { selectedNode } from "../stores";
  import { getDomainURL } from "../invokeAPI";
  import DelSetAlarm from "./../components/DelSetAlarm/DelSetAlarm.svelte";
  import { setAlarmConfig, screensToShow } from "./../../config";
  import { currentPage, availableScreens } from "./../stores";
  $: selectedNodeId = $selectedNode?.id;
  $: console.log(selectedNodeId);
  const getPermission = function (currentScreen, allocatedScreens) {
    if (
      currentScreen &&
      screensToShow &&
      Object.keys(screensToShow).includes(currentScreen)
    ) {
      const screen = screensToShow[currentScreen].screen;
      const screenObject = allocatedScreens.find(
        (item) => item.Name === screen
      );
      return screenObject.Permission;
    }
    return null;
  };
  $: screenPermission = getPermission($currentPage, $availableScreens);
</script>

<div class="del-page-conatiner">
  <DelSetAlarm
    baseURL={getDomainURL()}
    isWritePermission={screenPermission === "RW"}
    currentScreen={"Set Alarm"}
    NodeID={selectedNodeId}
    rangeWidgetConfiguration={setAlarmConfig.rangeWidgetConfiguration}
    tabOptions={setAlarmConfig.tabOptions}
  />
</div>
