<script>
  import { onMount, onDestroy } from "svelte";
  import * as signalR from "@microsoft/signalr";
  import Loader from "./../Loader/Loader.svelte";

  export let baseURL = "";
  export let currentNodeId = "";
  export let currentScreen = "Dashboard";
  export let alertTitle = {
    Critical: "Critical",
    Warning: "Warning",
    Informational: "Informational",
  };
  export let selectedNodeDetails = null;
  export let isHorizontal = true;
  export let isViewAll = true;
  export let isLabelClickable = true;
  export let isChildNodeIncluded = true;
  export let excludeAlertEvents = true;
  export let isRealTime = true;
  export let title = "Real time events";
  export let uniqueId = "Alert Count";
  export let startTime = 0;
  export let endTime = 0;
  export let viewAllClicked = null;
  export let categoryClicked = null;

  let data = [];
  let count = [];
  let alertCount = {};
  let categoryCount = new Object();
  let childNodeIds = [];
  let endPoint;
  let payload;
  let isDataAwaiting = false;
  let alertWS = null;

  const companyId = localStorage.getItem("companyId");
  const appId = sessionStorage.getItem("appId");

  const getHeader = function () {
    const companyId = localStorage.getItem("companyId");
    const appId = sessionStorage.getItem("appId");
    const access_token = "Bearer " + localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      companyid: companyId,
      applicationid: appId,
      Authorization: access_token,
      "access-origin": `${currentScreen}/R`,
    };
    return headers;
  };

  const checkIfIsRealTime = function () {
    return isRealTime;
  };

  $: if (currentNodeId && baseURL && (isRealTime || !isRealTime)) {
    invokeAPIs();
  }

  $: if (startTime && endTime && alertTitle && !checkIfIsRealTime()) {
    invokeAPIs();
  }

  async function getWS() {
    try {
      const payload = [
        {
          includeChildNode: true,
          nodeid: currentNodeId,
          type: "AlertCount",
          uniqueId: uniqueId, // You can generate a unique identifier here based on your requirements
        },
      ];

      const response = await fetch(
        `${baseURL}/realtimedata/api/Parameters/AlertCount/live`,
        {
          method: "POST", // Use 'GET' or 'POST' based on your API requirements
          headers: getHeader(),
          body: JSON.stringify(payload), // Convert payload to JSON if needed
        }
      );

      if (!response.ok) {
        let error = await response.json();
        throw new Error(error);
      }
      data = await response.json();
      getWSOnSuccess(data);
    } catch (error) {
      console.error(error);
    }
  }

  const invokeAPIs = function () {
    if (!(currentNodeId && baseURL)) return;

    if (isRealTime) {
      endPoint = `alert/api/Configuration/events/count?includeChildNodes=${isChildNodeIncluded}`;
      payload = [currentNodeId];
    } else {
      if (!(startTime && endTime)) return;
      endPoint = `alert/api/configuration/alerthistory`;
      payload = {
        AlertCategory: Object.keys(alertTitle),
        IncludeChildren: isChildNodeIncluded,
        NodeId: currentNodeId,
        StartTime: startTime,
        EndTime: endTime,
        ExcludeAlertEvents: excludeAlertEvents,
      };
    }
    fetchData();
  };

  async function fetchData() {
    try {
      const url = `${baseURL}/${endPoint}`;
      const options = {
        method: "POST",
        headers: getHeader(),
        body: JSON.stringify(payload),
      };
      isDataAwaiting = true;

      const response = await fetch(url, options);
      isDataAwaiting = false;

      if (!response.ok) {
        isDataAwaiting = false;

        let error = await response.json();
        throw new Error(error);
      }
      data = await response.json();

      if (isRealTime) {
        getWS();

        const match = data.find((item) => item.NodeId === currentNodeId);

        let _alertCount = {
          Critical: 0,
          Informational: 0,
          Warning: 0,
        };

        if (match && match.Count) {
          for (let count of match.Count) {
            categoryCount[count.triggerApplicationId] = count;
            _alertCount.Critical += count.Critical;
            _alertCount.Informational += count.Informational;
            _alertCount.Warning += count.Warning;
          }
        }

        alertCount = { ..._alertCount };
      } else {
        let eventCount = data.EventCount;
        Object.keys(alertTitle).forEach((key) => {
          alertCount[alertTitle[key]] = eventCount[key];
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  function getWSOnSuccess(data) {
    if (alertWS) alertWS.stop();
    customWebSocketConnectionAlerts(data.webSocketMethod, data.webSocketUrl);
  }

  function customWebSocketConnectionAlerts(webSocketMethod, webSocketUrl) {
    if (!webSocketMethod || !webSocketUrl) return false;
    alertWS = new signalR.HubConnectionBuilder()
      .withUrl(webSocketUrl, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .withAutomaticReconnect()
      .build();

    alertWS.on(webSocketMethod, function (_data) {
      let data = JSON.parse(_data);
      WSVFormatAlertCount(data);
    });

    alertWS
      .start()
      .then(function () {
        alertWS
          .invoke(
            "JoinGroup",
            companyId +
              "_" +
              appId +
              "_" +
              currentNodeId +
              "_" +
              uniqueId +
              "_AlertCount"
          )
          .catch(function (err) {
            return console.error({
              signalrerr: err.toString(),
            });
          });
      })
      .catch(function (e) {
        console.error("ws start error", e);
      });

    alertWS.onreconnected(function () {
      alertWS
        .invoke(
          "JoinGroup",
          companyId +
            "_" +
            appId +
            "_" +
            currentNodeId +
            "_" +
            uniqueId +
            "_AlertCount"
        )
        .catch(function (err) {
          return console.error({
            signalrerr: err.toString(),
          });
        });
    });
  }

  function setAlertCount(nodeMatch, totalAlertCount) {
    if (nodeMatch && nodeMatch.Count) {
      for (let count of nodeMatch.Count) {
        if (totalAlertCount[count.triggerApplicationId]) {
          totalAlertCount[count.triggerApplicationId].Critical +=
            count.Critical;
          totalAlertCount[count.triggerApplicationId].Informational +=
            count.Informational;
          totalAlertCount[count.triggerApplicationId].Warning += count.Warning;
          if (
            count.AcknowledgedCount !== undefined &&
            count.AcknowledgedCount !== null &&
            !isNaN(count.AcknowledgedCount)
          ) {
            totalAlertCount[count.triggerApplicationId].Acknowledged +=
              count.AcknowledgedCount;
          }
        } else {
          totalAlertCount[count.triggerApplicationId] = count;
        }
      }
    }
    console.log("this is setAlerCount return", totalAlertCount);
    return totalAlertCount;
  }

  function getChildNodes(hierarchy) {
    hierarchy.forEach((node) => {
      childNodeIds.push(node.id);
      if (node.children.length) {
        getChildNodes(node.children);
      }
    });
  }

  function validateWebSocketData(data) {
    if (
      data.DataSizeExceeded ||
      data.WebSocketSendFailed ||
      data.InvokeAPI ||
      data.InvokeSubscription
    ) {
      return false;
    } else {
      return true;
    }
  }

  function WSVFormatAlertCount(data) {
    if (!validateWebSocketData(data)) {
      console.error(
        "WebSocket update failed, calling GetAlertCount API from delAlarmSummary Prefab!!!"
      );
      fetchData();
      return;
    }

    console.log("Alert Count Websocket update received", data);

    let _alertCount = {};

    const match = data.find((item) => item.NodeId === currentNodeId)
        if (match) {
      _alertCount = setAlertCount(match, _alertCount);
    }
    childNodeIds = [];

    if (selectedNodeDetails) {
      getChildNodes(selectedNodeDetails.children);
    }
    data.map((item) => {
      if (childNodeIds.includes(item.NodeId)) {
        _alertCount = setAlertCount(item, _alertCount);
      }
    });

    for (let item in _alertCount) {
      categoryCount[item] = _alertCount[item];
    }
    let totalAlertCount = {
      Critical: 0,
      Informational: 0,
      Warning: 0,
    };
    for (let item of Object.values(categoryCount)) {
      totalAlertCount.Critical += item.Critical;
      totalAlertCount.Informational += item.Informational;
      totalAlertCount.Warning += item.Warning;
    }
    alertCount = { ...totalAlertCount };
  }

  onDestroy(() => {
    if (alertWS) alertWS.stop();
  });

  function getClassForCategory(category) {
    switch (category) {
      case "Critical":
        return "critical-count";
      case "Warning":
        return "warning-count";
      case "Informational":
        return "info-count";
      default:
        return "";
    }
  }

  function alertClicked(category) {
    isViewAll && categoryClicked && categoryClicked(category);
  }
</script>

<svelte:head>
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
  <link
    href="https://fonts.googleapis.com/css2?family=Roboto&display=swap"
    rel="stylesheet"
  />
</svelte:head>

<div class={`${isHorizontal ? "" : "widget-vertical"} widget-normal`}>
  <div class="title-div">
    <div class="widget-title">{title}</div>
    {#if isDataAwaiting}
      <Loader />
    {/if}
    {#if isViewAll}
      <div
        class="view-all"
        on:click={() => {
          viewAllClicked && viewAllClicked();
        }}
        on:keypress={() => {
          viewAllClicked && viewAllClicked();
        }}
      >
        View All
      </div>
    {/if}
  </div>
  <div class="widget-data">
    {#each Object.entries(alertTitle) as [category, displayName] (category)}
      <div class="alert-div">
        <div
          class={`alert-title ${isViewAll ? "clickable" : ""}`}
          on:click={isLabelClickable ? alertClicked(category) : ""}
          on:keypress={isLabelClickable ? alertClicked(category) : ""}
        >
          {displayName}
        </div>
        <div
          class={`${getClassForCategory(category)} ${
            isViewAll ? "clickable" : ""
          }`}
          on:click={isLabelClickable ? alertClicked(category) : ""}
          on:keypress={isLabelClickable ? alertClicked(category) : ""}
        >
          {alertCount[category] ?? 0}
        </div>
      </div>
    {/each}
  </div>
</div>

<style>
  .title-div {
    display: flex;
    justify-content: space-between;
    padding: 12px 22px;
  }

  .widget-normal {
    width: 100%;
    height: 100%;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
    position: relative;
  }

  .clickable {
    cursor: pointer;
  }

  .widget-data {
    display: flex;
    justify-content: space-between;
    padding: 0 22px;
  }

  .widget-vertical .widget-data {
    flex-direction: column;
  }

  .widget-title {
    text-align: left;
    font: normal normal bold 14px/19px Roboto;
    letter-spacing: 0px;
    color: #222222;
    opacity: 1;
  }

  .view-all {
    text-align: left;
    text-decoration: underline;
    font: normal normal normal 12px/16px Roboto;
    letter-spacing: 0px;
    color: #222222;
    opacity: 0.9;
    cursor: pointer;
  }

  .alert-div {
    display: inline-block;
  }

  .alert-title {
    letter-spacing: var(--unnamed-character-spacing-0);
    text-align: center;
    font: normal normal normal 14px/19px Roboto;
    letter-spacing: 0px;
    color: #2d3860;
    text-transform: capitalize;
    opacity: 1;
    cursor: pointer;
    padding-bottom: 8px;
  }

  .critical-count {
    text-align: center;
    font: normal normal bold 20px/26px Roboto;
    letter-spacing: 0px;
    color: #d20000;
    opacity: 1;
    cursor: pointer;
  }

  .warning-count {
    text-align: center;
    font: normal normal bold 20px/26px Roboto;
    letter-spacing: 0px;
    color: #e8a200;
    opacity: 1;
    cursor: pointer;
  }

  .info-count {
    text-align: center;
    font: normal normal bold 20px/26px Roboto;
    letter-spacing: 0px;
    color: #1b9e58;
    opacity: 1;
    cursor: pointer;
  }
</style>
