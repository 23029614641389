// Common Configurations
export const PREVIEW_DOMAIN_URL = "https://devplatformapi.delpheon.io";
export const DEV_DOMAIN_URL = "https://devplatformapi.delpheon.io";
export const QA_DOMAIN_URL = "https://qanewplatformapi.delpheon.io";
export const PRODUCTION_DOMAIN_URL = "";

export const PREVIEW_ANALYTICS_DOMAIN_URL = "https://analytics-dev.delpheon.io";
export const DEV_ANALYTICS_DOMAIN_URL = "https://analytics-dev.delpheon.io";
export const QA_ANALYTICS_DOMAIN_URL = "https://analytics-ae-qanew.delpheon.io";
export const PRODUCTION_ANALYTICS_DOMAIN_URL = "";

export const PREVIEW_DEPLOYED_URL = "http://localhost:3000";
export const DEV_DEPLOYED_URL = "https://co2h2o2-devnew.delpheon.io";
export const QA_DEPLOYED_URL = "https://co2m-qanew.delpheon.io";
export const PRODUCTION_DEPLOYED_URL = "";

export const APP_VERSION = "2.0.4";

// Configurations for Header

export const pathNameSSPA = "/co2_h2o2";
// export const pathNameSSPA = "/co2monitoring";
export const infoboardScreenDetails = {
  show: true,
  screenName: "Infoboard",
  displayName: "Infoboard",
};
export const pagesWithDatePicker = ["Reports"]; //should be the corresponding displayName
export const screensToShow = {
  "/": { screen: "Dashboard", displayName: "Dashboard" },
  "/AlarmViewer": { screen: "Alarm Viewer", displayName: "Alarm Viewer" },
  "/DigitalTwin": { screen: "Digital Twin", displayName: "Digital Twin" },
  "/SetAlarm": { screen: "Set Alarm", displayName: "Set Alarm" },
  "/Reports": { screen: "Reports", displayName: "Reports" },
  "/Error": { screen: "Dashboard", displayName: "Error" },
};

// Configurations for Dashboard

export const co2RealTimeLabel = {
  parameterCategory: "CO2 Level",
  deviceCategory: "Environmental_Sensor",
  parameterType: "Basic",
  readingUnit: "PPM",
  readingTitle: "CO2",
  uniqueID: "co2RealTimeLabel",
};

export const tempRealTimeLabel = {
  parameterCategory: "Temperature",
  deviceCategory: "Environmental_Sensor",
  parameterType: "Basic",
  readingUnit: "°C",
  readingTitle: "Temp",
  uniqueID: "tempRealTimeLabel",
};

export const humidityRealTimeLabel = {
  parameterCategory: "Relative Humidity",
  deviceCategory: "Environmental_Sensor",
  parameterType: "Basic",
  readingUnit: "%",
  readingTitle: "Humidity",
  uniqueID: "humidityRealTimeLabel",
};

export const batteryRealTimeLabel = {
  parameterCategory: "Battery",
  deviceCategory: "Environmental_Sensor",
  parameterType: "Basic",
  readingUnit: "%",
  readingTitle: "Battery",
  uniqueID: "batteryRealTimeLabel",
};

export const trendConfiguration = {
  parameterDetails: [
    {
      name: "Temperature °C",
      DeviceCategory: ["Environmental_Sensor", "Virtual_Meter"],
      ParameterCategory: ["Temperature"],
      type: "Basic",
      aggregation: "avg",
      parameterId: null,
      unit: "",
      oppositeAxis: false,
    },
    {
      name: "RH %",
      DeviceCategory: ["Environmental_Sensor", "Virtual_Meter"],
      ParameterCategory: ["Relative Humidity"],
      type: "Basic",
      aggregation: "avg",
      parameterId: null,
      unit: "",
      oppositeAxis: true,
    },
    {
      name: "CO2-PPM",
      DeviceCategory: ["Environmental_Sensor", "Virtual_Meter"],
      ParameterCategory: ["CO2 Level"],
      type: "Basic",
      aggregation: "avg",
      parameterId: null,
      unit: "",
      oppositeAxis: true,
    },
    {
      name: "H2O2-PPM",
      DeviceCategory: ["H2O2_Sensor", "Virtual_Meter"],
      ParameterCategory: ["H2O2 Level"],
      type: "Basic",
      aggregation: "avg",
      parameterId: null,
      unit: "",
      oppositeAxis: true,
    },
  ],
  downloadFileName: "Temperature, CO2 and Humidity",
  chartColors: ["#FF3602", "#00930D", "#00BFFF", "#331BFF"],
  title: "Temperature, CO2 and Humidity",
  chartHeight: "350px",
  uniqueID: "tempCO2HumidityTrend",
  dateTimeFormat: "DD MMM YY HH:mm",
  dateTimeFormatForSingleDay: "DD MMM YYYY",
  showErrorPercentage: true,
  errorLabel: "Error Percentage",
  errorParameterCategory: "Error",
  showAnnotations: true,
  annotationsList: [
    {
      Title: "CO2 Critical",
      Category: "Critical",
      Color: "#fa3838",
      YAxisIndex: 2,
      ReadingType: "value",
      DeviceCategory: ["Environmental_Sensor", "Virtual_Meter"],
      ParameterCategory: ["CO2 Level"],
      ParameterType: "Basic",
    },
    {
      Title: "CO2 Warning",
      Category: "Warning",
      Color: "#F7BE00",
      YAxisIndex: 2,
      ReadingType: "min",
      DeviceCategory: ["Environmental_Sensor", "Virtual_Meter"],
      ParameterCategory: ["CO2 Level"],
      ParameterType: "Basic",
    },
  ],
};

// Configurations for Report

export const eventReportConfig = {
  buttonLabel: "Alarm History",
  showSubmitButton: false,
  options: [
    { name: "Critical", id: "Critical", value: "Critical" },
    { name: "Informational", id: "Informational", value: "Informational" },
    { name: "Warning", id: "Warning", value: "Warning" },
  ],
  columnsToShow: [
    "EventName",
    "EventCategory",
    "Source",
    "OccuredOn",
    "CurrentStatus",
    "RemovedOn",
    "IsAcknowledged",
    "AcknowledgedBy",
    "AlertValue",
    "Comment",
    "IgnoreStatus",
  ],
  columnDetails: {
    EventName: {
      show: true,
      displayName: "Event Name",
    },
    EventCategory: {
      show: true,
      displayName: "Event Class",
    },
    Source: {
      show: true,
      displayName: "Source",
    },
    OccuredOn: {
      show: true,
      displayName: "Occured On",
      isDateTime: true,
    },
    CurrentStatus: {
      show: true,
      displayName: "Status",
    },
    RemovedOn: {
      show: true,
      displayName: "Removed On",
      isDateTime: true,
    },
    IsAcknowledged: {
      show: true,
      displayName: "Ack Status",
      isBoolean: true,
    },
    AcknowledgedBy: {
      show: true,
      displayName: "Acked By",
    },
    AlertValue: {
      show: true,
      displayName: "Alert Value",
    },
    Comment: {
      show: true,
      displayName: "Comment",
    },
    IgnoreStatus: {
      show: true,
      displayName: "Ignore",
    },
  },
  showButtonForIgnore: true,
};

export const rawReportConfig = {
  buttonLabel: "Raw Report",
  dateTimeFormat: `MMMM DD YYYY HH:mm:ss`,
  showSubmitButton: false,
  options: [
    { name: "CO2", id: "CO2", value: "CO2" },
    { name: "Temperature", id: "Temperature", value: "Temperature" },
    { name: "Humidity", id: "Humidity", value: "Humidity" },
  ],
  columnDetails: [
    {
      columnTitle: "CO2",
      parameterCategories: ["CO2 Level"],
      deviceCategories: ["Environmental_Sensor"],
      parameterType: "Basic",
      unit: "(PPM)",
    },
    {
      columnTitle: "Temperature",
      parameterCategories: ["Temperature"],
      deviceCategories: ["Environmental_Sensor"],
      parameterType: "Basic",
      unit: "(°C)",
    },
    {
      columnTitle: "Humidity",
      parameterCategories: ["Relative Humidity"],
      deviceCategories: ["Environmental_Sensor"],
      parameterType: "Basic",
      unit: "(%)",
    },
  ],
  entriesPerPage: "10",
  reportTitle: "Raw Report",
  groupBy: "none",
  operation: "avg",
};

// Configuration for Set Alarm

export const setAlarmConfig = {
  rangeWidgetConfiguration: {
    CO2M: {
      title: "Set Alarm Level (PPM)",
      minrange: 0,
      maxrange: 10000,
      step: 200,
      gridnumbers: 10,
      alarmbuffer: 200,
    },
    Temperature: {
      title: "Set Alarm Level (°C)",
      minrange: -30,
      maxrange: 45,
      step: 1,
      gridnumbers: 5,
      leftBuff: -3,
      rightBuff: 4,
    },
    Humidity: {
      title: "Set Alarm Level (%)",
      minrange: 0,
      maxrange: 100,
      step: 5,
      gridnumbers: 10,
      leftBuff: -10,
      rightBuff: 10,
    },
    H2O2: {
      title: "Set Alarm Level (PPM)",
      minrange: 0,
      maxrange: 10,
      step: 1,
      gridnumbers: 10,
      alarmbuffer: 1,
    },
  },
  tabOptions: ["CO2M", "Temperature", "Humidity"],
};
