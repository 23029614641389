<script>
  import * as signalR from "@microsoft/signalr";
  import { getDeployedURL } from "./../../invokeAPI";
  import { onDestroy } from "svelte";

  export let baseURL;
  export let nodeDetails;
  export let currentScreen = "Dashboard";
  export let parameterType = "";
  export let parameterCategory = null;
  export let deviceCategory = [];
  export let readingUnit = "";
  export let readingTitle = "";
  export let updateTimestamp;
  export let uniqueID = "delRealtimeParameter";

  const companyId = localStorage.getItem("companyId");
  const appId = sessionStorage.getItem("appId");

  let wsPayload;
  let realTimeReadingsWS = null;
  let reading = "...";
  let timeStamp = 0;
  let responseData;
  let parameterID;

  const imageMappings = {
    "Relative Humidity": "humidity.png",
    Temperature: "temp.png",
    "CO2 Level": "co2.png",
    Battery: "battery.png",
  };

  $: endpoint = `realtimedata/api/Parameters/all`;

  $: {
    if (nodeDetails && endpoint && baseURL && currentScreen) {
      getParameterId();
    }
  }
  $: imageUrl = `url(${getImageFilename(parameterCategory)})`;
  // $: updateTimestamp(timeStamp);

  function convertToTwoDigits(value) {
    if (isNaN(value)) {
      return "No Data";
    }
    return (value + "").indexOf(".") > -1
      ? parseFloat(value).toFixed(2)
      : value;
  }

  function getImageFilename(param) {
    let filename = imageMappings[param];
    if (filename) {
      return `${getDeployedURL()}/images/${filename}`;
    }
  }
  const onGetDataAPISuccess = function (data) {
    if (!data) return;
    [responseData] = data;
    reading = convertToTwoDigits(responseData?.reading);
    timeStamp = responseData?.timestamp || 0;
    updateTimestamp(timeStamp);
  };
  function getParameterId() {
    parameterID = filterParameterId(
      parameterCategory,
      deviceCategory,
      parameterType
    );
    reading = "...";
    fetchAPI("POST", endpoint, onGetDataAPISuccess);
  }

  function filterParameterId(parameterCategories, deviceCategories, type) {
    if (parameterCategories && deviceCategories && nodeDetails && type) {
      switch (type) {
        case "Basic":
          if (nodeDetails.parameters && nodeDetails.parameters.length) {
            for (let basicParameter of nodeDetails.parameters) {
              if (
                deviceCategories.includes(basicParameter.DeviceCategory) &&
                parameterCategories.includes(basicParameter.ParameterCategory)
              ) {
                return basicParameter.ParameterID;
              }
            }
          }
          break;

        case "Derived":
          if (
            nodeDetails.derivedparameters &&
            nodeDetails.derivedparameters.length
          ) {
            for (let derivedParameter of nodeDetails.derivedparameters) {
              if (
                deviceCategories.includes(derivedParameter.DeviceCategory) &&
                parameterCategories.includes(derivedParameter.ParameterCategory)
              ) {
                return derivedParameter.DerivedParameterId;
              }
            }
          }
          break;

        default:
          console.error("Unspecified Parameter Type!!!");
          break;
      }
    }
    return null;
  }
  const getHeader = function () {
    const companyId = localStorage.getItem("companyId");
    const applicationId = sessionStorage.getItem("appId");
    const accessToken = localStorage.getItem("access_token");
    if (!(accessToken && applicationId && companyId)) return;
    let headers = {
      companyId: companyId,
      applicationId: applicationId,
      "access-origin": `${currentScreen}/R`,
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };
    return headers;
  };

  const fetchAPI = function (method, endpoint, onsuccess) {
    async function getResponse() {
      let headers = getHeader();
      let payload = [{ parameterId: parameterID, type: parameterType }];
      let options = { method, headers };

      if (!(payload[0].parameterId && payload[0].type)) {
        timeStamp = 0;
        updateTimestamp(timeStamp);
        return;
      }

      if (payload) options.body = JSON.stringify(payload);
      const response = await fetch(`${baseURL}/${endpoint}`, options);

      wsPayload = [
        {
          parameterId: parameterID,
          type: parameterType + "Parameter",
          uniqueId: uniqueID,
        },
      ];
      connectToWebSocket();

      if (!response?.ok) {
        throw new Error(`HTTP error! status:${response?.status}`);
      }

      const data = await response.json();

      return data;
    }
    getResponse()
      .then((data) => {
        onsuccess && onsuccess(data);
      })
      .catch((error) => {
        timeStamp = 0;
        updateTimestamp(timeStamp);
        console.error("API call failed", error);
      });
  };
  // web socket functions

  // connect to websocket
  async function connectToWebSocket() {
    try {
      const url = `${baseURL}/realtimedata/api/parameters/all/live`;
      const options = {
        method: "POST",
        headers: getHeader(),
        body: JSON.stringify(wsPayload),
      };
      if (realTimeReadingsWS) {
        realTimeReadingsWS.stop();
      }
      const response = await fetch(url, options);
      if (!response.ok) {
        let error = await response.json();
        throw new Error(error);
      }
      let data = await response.json();
      connectToWebSocketOnSuccess(data);
    } catch (error) {
      console.error(error);
    }
  }
  function connectToWebSocketOnSuccess(data) {
    if (data.length) {
      customWebSocketConnectionRealTime(
        data.map((item) => item.webSocketMethod),
        data[0].webSocketUrl
      );
    }
  }
  function customWebSocketConnectionRealTime(webSocketMethods, webSocketUrl) {
    if (!webSocketMethods.length || !webSocketUrl) return false;

    realTimeReadingsWS = new signalR.HubConnectionBuilder()
      .withUrl(webSocketUrl, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .withAutomaticReconnect()
      .build();
    webSocketMethods.forEach((method) => {
      realTimeReadingsWS.on(method, function (_data) {
        let data = JSON.parse(_data);

        WSUFormatRealTimeData(data);
      });
    });
    realTimeReadingsWS
      .start()
      .then(function () {
        realTimeReadingsWS
          .invoke(
            "JoinGroup",
            companyId + "_" + appId + "_" + uniqueID + "_BasicParameter"
          )
          .catch(function (err) {
            return console.error({
              signalrerr: err.toString(),
            });
          });
        realTimeReadingsWS
          .invoke(
            "JoinGroup",
            companyId + "_" + appId + "_" + uniqueID + "_DerivedParameter"
          )
          .catch(function (err) {
            return console.error({
              signalrerr: err.toString(),
            });
          });
      })
      .catch(function (e) {
        console.error("ws start error", e);
      });

    realTimeReadingsWS.onreconnected(function () {
      realTimeReadingsWS
        .invoke(
          "JoinGroup",
          companyId + "_" + appId + "_" + uniqueID + "_BasicParameter"
        )
        .catch(function (err) {
          return console.error({
            signalrerr: err.toString(),
          });
        });
      realTimeReadingsWS
        .invoke(
          "JoinGroup",
          companyId + "_" + appId + "_" + uniqueID + "_DerivedParameter"
        )
        .catch(function (err) {
          return console.error({
            signalrerr: err.toString(),
          });
        });
    });
  }

  function validateWebSocketData(data) {
    if (data.DataSizeExceeded || data.WebSocketSendFailed) {
      return false;
    } else {
      return true;
    }
  }
  function WSUFormatRealTimeData(data) {
    if (!validateWebSocketData(data)) {
      console.error("WebSocket update failed, calling RealTime API!!!");
      fetchAPI();
      return;
    }
    console.log(
      `Websocket Update received in DelRealtimeParameter of ${readingTitle}: ${data}`
    );
    if (data?.parameters.length) {
      let fileteredParameterId = parameterID;
      const match = data.parameters.find(
        (item) => item.parameterId === fileteredParameterId
      );
      if (match) {
        if (match && !isNaN(match.reading)) {
          reading = convertToTwoDigits(match.reading);
          timeStamp = data.unixtime;
          updateTimestamp(timeStamp);
          return;
        }
        reading = null;
        timeStamp = data.unixtime;
        updateTimestamp(timeStamp);
      }
    }
  }
  onDestroy(() => {
    if (realTimeReadingsWS) {
      realTimeReadingsWS.stop();
    }
  });
</script>

<svelte:head>
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
  <link
    href="https://fonts.googleapis.com/css2?family=Roboto&display=swap"
    rel="stylesheet"
  />
</svelte:head>

<div class="del-parameter-card-container">
  <div class="del-parameter-header-container">
    <div
      class="image-container"
      title={readingTitle}
      style="background-image:{imageUrl}"
    />
    <label for="" class="del-parameter-title" title={readingTitle}
      >{readingTitle}</label
    >

    {#if (reading || reading === 0) && reading !== "..."}
      <label
        for=""
        class="del-parameter-unit"
        title={responseData?.errorMessage}>{reading} {readingUnit}</label
      >
    {:else}
      <label for="" class="del-parameter-unit-no-data" title={"No Data"}
        >No Data</label
      >
    {/if}
  </div>
</div>

<style>
  .del-parameter-card-container {
    font: normal normal 18px/24px Roboto;
    width: 100%;
    background: #ffffff;
    min-height: auto;
    border-radius: 5px;
  }
  .del-parameter-header-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .image-container {
    max-height: 63px;
    width: 100%;
    height: 63px;
    object-fit: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .del-parameter-title {
    padding: 0 !important;
    width: 100%;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 15px;
    margin-bottom: 5px;
    color: #61656c;
    font-weight: 400;
  }
  .del-parameter-unit {
    color: #f37a25;
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 5px;
    text-align: center;
    width: 100%;
    padding: 0 !important;
  }

  .del-parameter-unit-no-data {
    color: #6f6d6c;
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 5px;
    text-align: center;
    width: 100%;
    padding: 0 !important;
  }
</style>
