<script>
  import RangeSlider from "svelte-range-slider-pips";

  export let sliderProperty;
  export let sliderChange;
  export let from = 0;
  export let to = 0;
  export let isDisabled;
  let min;
  let max;
  let step;
  let values;
  let disabled;
  $: {
    values = [from, to];
  }
  $: sliderChange(from, to);

  $: {
    if (sliderProperty) {
      step = sliderProperty.step;
      min = sliderProperty.minrange;
      max = sliderProperty.maxrange;
      disabled = !isDisabled;
    }
  }
  function handleChange(e) {
    e.detail.activeHandle == 0
      ? (from = e.detail.value)
      : (to = e.detail.value);
  }
</script>

<div class="Slider">
  <RangeSlider
    bind:values
    pips
    all="label"
    first="label"
    last="label"
    range
    float
    {min}
    {max}
    {step}
    {disabled}
    on:change={handleChange}
  />
</div>

<style>
  .Slider {
    margin-top: 50px;
  }
  :root {
    --range-slider: hsl(180, 3.9%, 84.9%);
    --range-handle-inactive: #f37a25;
    --range-handle: #f37a25;
    --range-handle-focus: #f37a25;
    --range-handle-border: hsl(205, 6%, 38%);
    --range-range-inactive: hwb(180 84% 15%);
    --range-range: hsl(180, 3.9%, 84.9%);
    --range-float-inactive: #f37a25;
    --range-float: #f37a25;
    --range-float-text: hsl(0, 0%, 100%);

    --range-pip: hsl(210, 14.3%, 53.3%);
    --range-pip-text: hsl(100, 1%, 59%);
    --range-pip-active: hsl(69, 14%, 10%);
    --range-pip-active-text: #fdc500;
    --range-pip-hover: hsl(180, 10%, 6%);
    --range-pip-hover-text: hsl(0, 96%, 26%);
    --range-pip-in-range: #fdc500;
    --range-pip-in-range-text: #109e46;
  }
  :global(.rangeSlider .rangeFloat) {
    border-radius: 10px; /* Border radius */
    padding: 10px; /* Padding */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Box shadow */
    max-width: 200px; /* Maximum width */
  }
  :global(.rangeSlider .rangeFloat::after) {
    content: "";
    position: absolute;
    top: 96%;
    left: 56%;
    border: solid transparent;
    border-width: 7px;
    border-top-color: #f37a25;
    margin-left: -8px;
  }
  :global(.rangeSlider > .rangePips) {
    font-size: 10px;
  }
  :global(.rangeSlider > .rangeHandle > .rangeFloat) {
    opacity: 1;
    font-size: 12px;
  }

  :global(.rangePips .pip .pipVal) {
    display: none;
  }
  :global(.rangeSlider > .rangePips > .pip:nth-of-type(1) .pipVal) {
    display: block !important;
  }
  :global(.rangeSlider > .rangePips > .pip:last-child .pipVal) {
    display: block !important;
  }
  :global(.rangePips .pip:nth-of-type(4n) .pipVal) {
    display: block;
  }
  :global(.rangeSlider.range:not(.min):not(.max) .rangeNub) {
    border-radius: 10em !important;
  }
  :global(.rangePips .pip:not(.selected):not(.in-range) .pipVal) {
    color: #109e46;
  }

  :global(.rangePips .pip.in-range .pipVal) {
    color: #fdc500 !important;
  }

  :global(.rangePips .pip.selected ~ .pip .pipVal) {
    color: #fa3838;
  }
</style>
