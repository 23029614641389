<script>
  import { onDestroy } from "svelte";
  import moment from "moment";
  import * as signalR from "@microsoft/signalr";
  import Icon from "@iconify/svelte";
  import Loader from "./Loader.svelte";
  import Toastify from "toastify-js";

  let data = [];
  let ogResponse = [];
  let tableHeadingKeys = [];
  let alertWS = null;
  let childNodeIds = [];

  export let baseURL = "";
  export let currentNodeId = "";
  export let currentScreen = "Events";
  export let categoryOrder = {
    category: { Critical: 0, Warning: 1, Informational: 2 },
    currentstatus: { Active: 0, "In-active": 1 },
  };
  export let isMeridiemTime = false;
  export let tableHeadings = {
    alertname: "Alert",
    category: "Class",
    currentstatus: "Status",
    nodeName: "Source",
    createdDate: "Occured On",
    acknowledgedDate: "Acknowledged On",
    acknowledgedBy: "Acknowledged By",
    isAcknowledged: "Acknowledged Status",
    action: "Action",
    ignore: "Disable",
  };
  export let filterColumns = {
    category: [],
    currentstatus: [],
    nodeName: [],
    isAcknowledged: [],
  };
  export let sortColumns = [
    "alertname",
    "category",
    "currentstatus",
    "createdDate",
    "acknowledgedDate",
  ];
  export let selectedNodeDetails = null;
  export let ackBtnLabel = "Acknowledge";
  export let isViewCommentEnabled = true;
  export let viewCommentBtnLabel = "View Comment";
  export let disableBtnLabel = "Disable";
  export let uniqueId = "EventTable";
  export let isWritePermission = null;
  export let isAPIAwaiting = false;
  export let childNodeCheckboxPosition = "right";
  export let isAlertRedirectable = true;
  export let alertNameClicked = null;

  let selectedFilter = setSelectedFilter();
  let alertsGroupedByAppId = {};
  let isFirstTime = true;
  let currAlert = "";
  let currCategory = "";
  let currId = "";
  let eventId = "";
  let commentText = "";
  let sortType = "";
  let ackedBy = "";
  let ackedOn = "";
  let showNoDataMessage = false;

  let isChildNodeIncluded = sessionStorage.getItem("isChildNodeIncluded")
    ? JSON.parse(sessionStorage.getItem("isChildNodeIncluded"))
    : true;
  let isSubmitting = false;
  let isDataAscending = false;
  let isPriorityAscending = false;
  let isDropdownOpen = {
    category: false,
    currentstatus: false,
    nodeName: false,
    isAcknowledged: false,
  };
  let isPopupOpen = false;
  let isCommentPopupOpen = false;
  let isDisableCommentOpen = false;

  let currentPage = 1;
  let itemsPerPage = 5;
  let start = (currentPage - 1) * itemsPerPage;
  let end = start + itemsPerPage;
  let paginatedData = [];

  const userName = localStorage.getItem("uname");
  const companyId = localStorage.getItem("companyId");
  const appId = sessionStorage.getItem("appId");

  function getHeader() {
    const access_token = "Bearer " + localStorage.getItem("access_token");
    if (!(access_token && appId && companyId)) return;
    let headers = {
      "Content-Type": "application/json",
      companyid: companyId,
      applicationid: appId,
      Authorization: access_token,
    };
    return headers;
  }
  $: {
    if (currentNodeId) {
      isAPIAwaiting = true;
      fetchData();
    }
  }

  for (const key in tableHeadings) {
    if (tableHeadings[key] !== false) {
      tableHeadingKeys.push(key);
    }
  }

  function setSelectedFilter() {
    let filters = {};
    if (sessionStorage.getItem("eventViewerSelectedFilters")) {
      filters = sessionStorage.getItem("eventViewerSelectedFilters");
      return JSON.parse(filters);
    }
    filters = sessionStorage.getItem("eventViewerFilterColumns");
    return filters ? JSON.parse(filters) : {};
  }

  async function fetchData() {
    try {
      showNoDataMessage = false;
      const url = `${baseURL}/alert/api/Configuration/${currentNodeId}/events/details?includeAllAlerts=true&includeDisabledAlerts=true&includeChildNodes=${isChildNodeIncluded}`;
      const headers = {
        ...getHeader(),
        "access-origin": `${currentScreen}/R`,
      };
      const options = {
        method: "GET",
        headers: headers,
      };
      if (!currentNodeId) {
        isAPIAwaiting = false;
        return;
      }
      const response = await fetch(url, options);
      isAPIAwaiting = false;
      if (!response.ok) {
        let error = await response.json();
        throw new Error(error);
      }
      let apiData = await response.json();

      alertsGroupedByAppId = groupByCategory(apiData, "triggerApplicationId");

      data = apiData.map((item) => {
        item.currentstatus =
          item.currentstatus === "Active" ? "Active" : "In-active";

        item.isAcknowledged = item.isAcknowledged ? "YES" : "NO";
        item.acknowledgedBy = item.acknowledgedBy ? item.acknowledgedBy : "";

        return item;
      });

      ogResponse = data.sort((a, b) => b.createdDate - a.createdDate);
      getWS();
      for (const key in filterColumns) {
        if (key !== "isAcknowledged" && key !== "category")
          filterColumns[key] = [];
      }
      for (const key in filterColumns) {
        ogResponse.forEach((item) => {
          if (item[key] && !filterColumns[key].includes(item[key]))
            filterColumns[key].push(item[key]);
        });
      }
      sessionStorage.setItem(
        "eventViewerFilterColumns",
        JSON.stringify(filterColumns)
      );
      if (!Object.keys(selectedFilter)?.length) {
        selectedFilter = { ...filterColumns };
      } else {
        const filterColumnsKeys = Object.keys(filterColumns);
        const selectedFilterKeys = Object.keys(selectedFilter);
        for (const key of filterColumnsKeys) {
          if (
            !selectedFilterKeys.includes(key) ||
            (key === "nodeName" && !isFirstTime)
          ) {
            selectedFilter[key] = filterColumns[key];
          }
        }
      }
      isFirstTime = false;
      data = filterData();

      const totalPages = Math.ceil(data?.length / itemsPerPage);
      if (currentPage > totalPages) {
        currentPage = totalPages;
      }
      updatePagination();
    } catch (error) {
      console.error(error);
      showNoDataMessage = true;
    }
  }

  function formatDate(epoch) {
    if (epoch && epoch != 0) {
      const date = moment(epoch * 1000);
      const formattedDate = date.format("DD MMM YYYY");
      return formattedDate;
    } else {
      return "";
    }
  }

  function formatTime(epoch) {
    if (epoch && epoch != 0) {
      const date = moment(epoch * 1000);
      if (isMeridiemTime) {
        const formattedTime = date.format("hh:mm a");
        return formattedTime;
      } else {
        const formattedTime = moment.unix(epoch).format("HH:mm:ss");
        return formattedTime;
      }
    } else {
      return "";
    }
  }

  function openPopup(id, evId, category, alert) {
    isPopupOpen = true;
    currAlert = alert;
    currCategory = category;
    currId = id;
    eventId = evId;
  }

  function closePopup() {
    isPopupOpen = false;
    currId = "";
    currAlert = "";
    currCategory = "";
    eventId = "";
    commentText = "";
  }

  function openCommentPopup(comment, alert, category, ackby, ackdate) {
    isCommentPopupOpen = true;
    commentText = comment;
    currAlert = alert;
    currCategory = category;
    ackedBy = ackby;
    ackedOn = formatDate(ackdate);
  }

  function closeCommentPopup() {
    isCommentPopupOpen = false;
    currId = "";
    commentText = "";
    currAlert = "";
    currCategory = "";
    ackedBy = "";
    ackedOn = "";
  }

  function handleCommentChange(event) {
    commentText = event.target.value;
  }

  function showSuccessToast() {
    Toastify({
      text: `Comment added successfully`,
      style: {
        background: "green",
      },
      duration: 3000,
      close: false,
    }).showToast();
    return;
  }

  function showFailureToast() {
    Toastify({
      text: `Failed to add comment`,
      style: {
        background: "red",
      },
      duration: 3000,
      close: false,
    }).showToast();
    return;
  }

  async function submitComment() {
    if (commentText.trim() === "") {
      return; // Don't submit an empty comment
    }
    isSubmitting = true;
    const headers = {
      ...getHeader(),
      "access-origin": `${currentScreen}/RW`,
    };

    try {
      const response = await fetch(
        `${baseURL}/alert/api/v2/Configuration/alerts/${currId}/events/${eventId}`,
        {
          method: "PATCH",
          headers: headers,
          body: JSON.stringify({ Comment: commentText }),
        }
      );

      if (response.ok) {
        closePopup();
        showSuccessToast();
      } else {
        console.error("Failed to submit comment:", response.statusText);
        showFailureToast();
      }
    } catch (error) {
      console.error("An error occurred while submitting the comment:", error);
      showFailureToast();
    } finally {
      isSubmitting = false;
    }
    // fetchData();
  }

  function openDisableCommentPopup(alert, event) {
    isDisableCommentOpen = true;
    currId = alert;
    eventId = event;
  }

  function closeDisableCommentPopup() {
    isDisableCommentOpen = false;
    currId = "";
    eventId = "";
  }

  async function ignoreAlert() {
    const headers = { ...getHeader(), "access-origin": `${currentScreen}/RW` };
    try {
      const response = await fetch(
        `${baseURL}/alert/api/Configuration/disablealert`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            AlertId: currId,
            EventId: eventId,
            IgnoredBy: userName,
          }),
        }
      );

      if (response.ok) {
        closePopup();
      } else {
        console.error("Failed to disable alert:", response.statusText);
      }
    } catch (error) {
      console.error("An error occurred while disabling the alert:", error);
    } finally {
      isDisableCommentOpen = false;
    }
    // fetchData();
  }

  async function getWS() {
    try {
      const payload = ogResponse.map((item) => ({
        parameterId: item.id,
        type: "AlertList",
        uniqueId: uniqueId, // You can generate a unique identifier here based on your requirements
      }));
      const headers = { ...getHeader(), "access-origin": `${currentScreen}/R` };

      if (!payload?.length) return;

      const response = await fetch(
        `${baseURL}/realtimedata/api/Parameters/AlertList/live`,
        {
          method: "POST", // Use 'GET' or 'POST' based on your API requirements
          headers,
          body: JSON.stringify(payload), // Convert payload to JSON if needed
        }
      );

      if (!response.ok) {
        let error = await response.json();
        throw new Error(error);
      }
      let wsData = await response.json();
      getWSOnSuccess(wsData);
    } catch (error) {
      console.error(error);
    }
  }

  function getWSOnSuccess(data) {
    if (alertWS) alertWS.stop();
    customWebSocketConnectionAlerts(data.webSocketMethod, data.webSocketUrl);
  }

  function customWebSocketConnectionAlerts(webSocketMethod, webSocketUrl) {
    if (!webSocketMethod || !webSocketUrl) return false;
    alertWS = new signalR.HubConnectionBuilder()
      .withUrl(webSocketUrl, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .withAutomaticReconnect()
      .build();

    alertWS.on(webSocketMethod, function (_data) {
      let data = JSON.parse(_data);
      UpdateAlertList(data);
    });

    alertWS
      .start()
      .then(function () {
        alertWS
          .invoke(
            "JoinGroup",
            companyId + "_" + appId + "_" + uniqueId + "_Alert"
          )
          .catch(function (err) {
            return console.error({
              signalrerr: err.toString(),
            });
          });
      })
      .catch(function (e) {
        console.error("ws start error", e);
      });

    alertWS.onreconnected(function () {
      alertWS
        .invoke(
          "JoinGroup",
          companyId + "_" + appId + "_" + uniqueId + "_Alert"
        )
        .catch(function (err) {
          return console.error({
            signalrerr: err.toString(),
          });
        });
    });
  }

  function getChildNodes(hierarchy) {
    hierarchy.forEach((node) => {
      childNodeIds.push(node.id);
      if (node.children.length) {
        getChildNodes(node.children);
      }
    });
  }

  // function combineWebSocketData(data) {
  //   if (data.chunkno === 1) {
  //     Prefab.Variables.webSocketDataCombined.setData([]);
  //   }
  //   Prefab.Variables.webSocketDataCombined.setData(
  //     Prefab.Variables.webSocketDataCombined.dataSet.concat(data.data)
  //   );
  //   if (data.totalchunkscount === data.chunkno) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  function validateWebSocketData(data) {
    if (data.DataSizeExceeded || data.WebSocketSendFailed || data.InvokeAPI) {
      return false;
    } else if (data.InvokeSubscription) {
      return false;
    } else {
      return true;
    }
  }

  function compareAndUpdateEventList(oldData, newData) {
    // Update old items
    let updatedData = oldData.map((item) => {
      const match = newData.find((event) => event.id === item.id);
      if (match) {
        return match;
      } else {
        return item;
      }
    });

    // Add new items that were not present in old list
    for (let item of newData) {
      const match = oldData.find((event) => event.id === item.id);
      if (!match) {
        updatedData = updatedData.concat(item);
      }
    }

    return updatedData;
  }

  //Function to update the events list based on websocket response
  function UpdateAlertList(websocketData) {
    // if (!Prefab.combineWebSocketData(websocketData)) {
    //     return;
    // }

    // const combinedWebSocketData = Prefab.Variables.webSocketDataCombined.getData();

    // let alertsGroupedByAppId = Prefab.groupByCategory(combinedWebSocketData, "triggerApplicationId");
    // console.log("Grouped Alerts", alertsGroupedByAppId);

    if (!validateWebSocketData(websocketData)) {
      console.error(
        "Web Socket Update failed, calling getAlerts API from delEventViewer Prefab!!!"
      );
      // Prefab.Variables.getAlerts.invoke();
      fetchData();
      return;
    }
    console.log("Alert List Web Socket Update", websocketData);
    //Concatenate unit to alert value and check for NaN values for acknowledged and created dates
    websocketData.forEach((item) => {
      // item.alertvalue = getAlertValueWithUnit(item.alertvalue, item.unit);
      item.acknowledgedDate = isNaN(item.acknowledgedDate)
        ? ""
        : item.acknowledgedDate;
      item.createdDate = isNaN(item.createdDate) ? "" : item.createdDate;
    });

    //Group alerts on the basis of their application id
    let alertsGrouped = groupByCategory(websocketData, "triggerApplicationId");
    // console.log("Grouped Alerts", alertsGroupedByAppId);

    for (let event of Object.keys(alertsGrouped)) {
      alertsGroupedByAppId[event] = alertsGrouped[event];
    }

    // console.log("Updated alerts grouped by AppId", Prefab.Variables.alertsGroupedByAppId.getData());
    // console.log("Flattening the list...", Object.values(Prefab.Variables.alertsGroupedByAppId.getData()).flat());

    const updatedEventList = Object.values(alertsGroupedByAppId).flat();
    // console.log("Updated and combined event list ", updatedEventList);

    // Find all alerts belonging to the selected node id
    let allEventsList = updatedEventList.filter(
      (item) => item.nodeId === currentNodeId
    );

    if (isChildNodeIncluded) {
      //Find all child node ids and filter the response to include their events
      childNodeIds = [];
      if (selectedNodeDetails) {
        getChildNodes(selectedNodeDetails.children);
      }
      allEventsList = allEventsList.concat(
        updatedEventList.filter((item) => childNodeIds.includes(item.nodeId))
      );
    }
    allEventsList = allEventsList.filter((item) => item.createdDate);

    //Compare the new alert list with old one and update it
    const comparedAndUpdatedEventList = compareAndUpdateEventList(
      ogResponse,
      allEventsList
    );

    comparedAndUpdatedEventList.sort(function (a, b) {
      if (parseInt(b.createdDate) - parseInt(a.createdDate) === 0) {
        if (a.alertname.toLowerCase() < b.alertname.toLowerCase()) return -1;
        if (a.alertname.toLowerCase() > b.alertname.toLowerCase()) return 1;
        return 0;
      } else return parseInt(b.createdDate) - parseInt(a.createdDate);
    });
    ogResponse = comparedAndUpdatedEventList.map((item) => {
      item.currentstatus =
        item.currentstatus === "Active" ? "Active" : "In-active";

      item.isAcknowledged =
        item.isAcknowledged === "YES" || item.isAcknowledged === true
          ? "YES"
          : "NO";
      item.acknowledgedBy = item.acknowledgedBy ? item.acknowledgedBy : "";

      return item;
    });

    ogResponse = ogResponse.sort((a, b) => b.createdDate - a.createdDate);

    for (const key in filterColumns) {
      filterColumns[key] = [];
    }
    for (const key in filterColumns) {
      ogResponse.forEach((item) => {
        if (item[key] && !filterColumns[key].includes(item[key]))
          filterColumns[key].push(item[key]);
      });
    }
    sessionStorage.setItem(
      "eventViewerFilterColumns",
      JSON.stringify(filterColumns)
    );
    if (!Object.keys(selectedFilter)?.length) {
      selectedFilter = { ...filterColumns };
    } else {
      const filterColumnsKeys = Object.keys(filterColumns);
      const selectedFilterKeys = Object.keys(selectedFilter);
      for (const key of filterColumnsKeys) {
        if (!selectedFilterKeys.includes(key)) {
          selectedFilter[key] = filterColumns[key];
        }
      }
    }

    data = filterData();
    const totalPages = Math.ceil(data?.length / itemsPerPage);
    if (currentPage > totalPages) {
      currentPage = totalPages;
    }
    updatePagination();
  }

  function groupByCategory(items, groupByItemName) {
    const groupByCategory = items.reduce((group, item) => {
      const categoryName = item[groupByItemName];
      group[categoryName] = group[categoryName] ? group[categoryName] : [];
      group[categoryName].push(item);
      return group;
    }, {});
    return groupByCategory;
  }

  function getAlertValueWithUnit(value, unit) {
    if (isNaN(value)) {
      return "No Data";
    }
    if (unit) {
      return `${convertToTwoDigits(value)} ${unit}`;
    } else {
      return convertToTwoDigits(value);
    }
  }

  //On Prefab destroy, close websocket connection
  onDestroy(() => {
    if (alertWS) alertWS.stop();
  });

  function convertToTwoDigits(value) {
    return (value + "").indexOf(".") > -1
      ? parseFloat(value).toFixed(2)
      : value;
  }

  function getClassForCategory(category) {
    switch (category) {
      case "Critical":
        return "critical-color";
      case "Warning":
        return "warning-color";
      case "Informational":
        return "informational-color";
      default:
        return "";
    }
  }

  // Function to toggle the dropdown
  function toggleDropdown(type) {
    for (const key in isDropdownOpen) {
      if (key !== type) isDropdownOpen[key] = false;
    }
    isDropdownOpen[type] = !isDropdownOpen[type];
  }

  function handleChildNode() {
    isChildNodeIncluded = !isChildNodeIncluded;
    sessionStorage.setItem(
      "isChildNodeIncluded",
      JSON.stringify(isChildNodeIncluded)
    );
    isAPIAwaiting = true;
    fetchData();
  }

  function handleSelection(event, type) {
    const { checked, value } = event.target;
    if (checked) {
      if (!selectedFilter[type].includes(value)) {
        selectedFilter[type] = [...selectedFilter[type], value];
      }
    } else {
      selectedFilter[type] = selectedFilter[type].filter(
        (item) => item !== value
      );
    }
    sessionStorage.setItem(
      "eventViewerSelectedFilters",
      JSON.stringify(selectedFilter)
    );

    data = filterData();
    if (value.length === 0) {
      isDropdownOpen = Object.fromEntries(
        Object.keys(isDropdownOpen).map((key) => [key, false])
      );
    }
    const totalPages = Math.ceil(data?.length / itemsPerPage);
    if (currentPage > totalPages) {
      currentPage = totalPages;
    }
    updatePagination();
  }

  function filterData() {
    const filteredData = ogResponse.filter((item) => {
      if (item.currentstatus === "Active") {
        let result = true;
        for (const key of Object.keys(selectedFilter)) {
          if (selectedFilter[key] && !selectedFilter[key].includes(item[key])) {
            result = false;
            break;
          }
        }

        return result;
      }
    });
    return filteredData;
  }

  function prevPage() {
    if (currentPage > 1) {
      currentPage -= 1;
      updatePagination();
    }
  }

  function nextPage() {
    if (currentPage < Math.ceil(data.length / itemsPerPage)) {
      currentPage += 1;
      updatePagination();
    }
  }

  function gotoPage(page) {
    currentPage = page;
    updatePagination();
  }

  function updatePagination() {
    if (!data?.length) currentPage = 1;
    start = (currentPage - 1) * itemsPerPage;
    end = start + itemsPerPage;
    paginatedData = data.slice(start, end);
    showNoDataMessage = paginatedData?.length === 0;
  }

  function sortClass(type) {
    sortType = type;
    data = data.sort(customSort);
    isPriorityAscending = !isPriorityAscending;
    updatePagination();
  }

  function customSort(a, b) {
    let categoryComparison = 0;
    if (sortType === "alertname") {
      if (isPriorityAscending) {
        categoryComparison = a.alertname.localeCompare(b.alertname);
      } else {
        categoryComparison = b.alertname.localeCompare(a.alertname);
      }
    } else {
      if (isPriorityAscending) {
        categoryComparison =
          categoryOrder[sortType][a[sortType]] -
          categoryOrder[sortType][b[sortType]];
      } else {
        categoryComparison =
          categoryOrder[sortType][b[sortType]] -
          categoryOrder[sortType][a[sortType]];
      }
    }

    if (categoryComparison === 0) {
      return parseInt(b.createdDate) - parseInt(a.createdDate);
    } else {
      return categoryComparison;
    }
  }

  function sortDate(type) {
    isDataAscending = !isDataAscending;

    if (isDataAscending) {
      data = data.sort((a, b) => a[type] - b[type]);
    } else {
      data = data.sort((a, b) => b[type] - a[type]);
    }

    updatePagination();
  }

  function isAllSpaces(inputString) {
    return inputString.trim() === "";
  }
</script>

<svelte:head>
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
  <link
    href="https://fonts.googleapis.com/css2?family=Roboto&display=swap"
    rel="stylesheet"
  />
</svelte:head>

{#if isPopupOpen}
  <div class="popup">
    <div class="popup-content">
      <div class="popup-header">
        <div class="title">Add Comment</div>
        <div class="alert-details">{currCategory}:{currAlert}</div>
      </div>

      <br />
      <div>
        <textarea
          class="comment-textarea"
          aria-multiline="true"
          maxlength="100"
          placeholder="Add Comment"
          bind:value={commentText}
          on:input={handleCommentChange}
        />
      </div>
      <br />
      <div class="popup-footer">
        <div class="footer-text">Upto 100 Characters</div>
        <div class="popup-btn">
          <div class="close-btn" on:click={closePopup} on:keypress={closePopup}>
            Close
          </div>
          <div>
            <button
              class="submit-btn"
              disabled={!commentText || isAllSpaces(commentText)}
              on:click={submitComment}>Submit</button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
{/if}

{#if isCommentPopupOpen}
  <div class="popup">
    <div class="popup-content">
      <div>
        <div class="popup-header">
          <div class="title">Comment</div>
          <div class="alert-details">{`${currCategory} : ${currAlert}`}</div>
        </div>

        <div class="popup-sub-title">
          <div>{ackedBy}</div>
          <div>{ackedOn}</div>
        </div>
      </div>
      <div class="comment-text">{commentText}</div>
      <div>
        <div
          class="close-btn"
          on:click={closeCommentPopup}
          on:keypress={closeCommentPopup}
        >
          Close
        </div>
      </div>
    </div>
  </div>
{/if}

{#if isDisableCommentOpen}
  <div class="popup">
    <div class="popup-content">
      <div>
        <div class="popup-header">
          <div class="title">Disable Event ?</div>
        </div>
      </div>
      <div class="comment-text">
        Are you sure you want to disable this event?
      </div>
      <div>
        <div class="popup-btn">
          <div
            class="close-btn"
            on:click={closeDisableCommentPopup}
            on:keypress={closeDisableCommentPopup}
          >
            Close
          </div>
          <div>
            <button class="submit-btn" on:click={ignoreAlert}>Submit</button>
          </div>
        </div>
      </div>
    </div>
  </div>
{/if}

<div class="del-event-table-container">
  {#if isAPIAwaiting}
    <Loader />
  {/if}
  <div class="body-container">
    <div
      class="{childNodeCheckboxPosition === 'left'
        ? 'checkbox-left'
        : 'checkbox-right'} checkbox-div"
    >
      <input
        type="checkbox"
        class="child-node-checkbox"
        checked={isChildNodeIncluded}
        on:change={handleChildNode}
      />Include Child Nodes
    </div>
  </div>
  <div class="table-container">
    <table class="event-table">
      <thead>
        <tr class="title-row">
          {#each tableHeadingKeys as heading}
            {#if (heading !== "action" && heading !== "ignore") || isWritePermission}
              <th class="sticky-class">
                <div class="th-parent">
                  <div class="th-title">{tableHeadings[heading]}</div>

                  {#if filterColumns[heading]}
                    <button
                      on:click={() => toggleDropdown(heading)}
                      class="filter-button"
                    >
                      <Icon icon="mdi:filter" />
                    </button>

                    {#if isDropdownOpen[heading]}
                      <div class="dropdown-content">
                        {#each filterColumns[heading] as elements}
                          <label>
                            <input
                              type="checkbox"
                              bind:group={selectedFilter[heading]}
                              value={elements}
                              on:change={(e) => handleSelection(e, heading)}
                            />
                            {elements}
                          </label>
                        {/each}
                      </div>
                    {/if}
                  {/if}
                  {#if sortColumns.includes(heading)}
                    {#if heading !== "acknowledgedDate" && heading !== "createdDate"}
                      <button
                        on:click={() => sortClass(heading)}
                        class="filter-button sort-button"
                      >
                        <Icon icon="fa:sort" />
                      </button>
                    {:else}
                      <button
                        on:click={() => sortDate(heading)}
                        class="filter-button sort-button"
                      >
                        <Icon icon="fa:sort" />
                      </button>
                    {/if}
                  {/if}
                </div>
              </th>
            {/if}
          {/each}
        </tr>
      </thead>
      <tbody>
        {#if showNoDataMessage}
          <tr
            ><td class="nodata-text" colspan={tableHeadingKeys.length}
              >No Data Found</td
            ></tr
          >
        {:else}
          {#each paginatedData as item (item.id)}
            <tr class="table-text">
              {#each Object.entries(tableHeadings) as [key, value]}
                {#if (key !== "action" && key !== "ignore") || isWritePermission}
                  {#if value !== false}
                    {#if key === "createdDate" || key === "acknowledgedDate"}
                      <td class="sticky-class">
                        <div class="date-and-time">
                          <div class="date-div">{formatDate(item[key])}</div>
                          <div class="time-div">{formatTime(item[key])}</div>
                        </div>
                      </td>
                    {:else if key === "action"}
                      <td class="sticky-class"
                        >{#if item.isAcknowledged === "YES"}
                          <button
                            on:click={() => {
                              if (isViewCommentEnabled)
                                openCommentPopup(
                                  item.comment,
                                  item.alertname,
                                  item.category,
                                  item.acknowledgedBy,
                                  item.acknowledgedDate
                                );
                            }}
                            class="ack-btn">{viewCommentBtnLabel}</button
                          >
                        {:else}
                          <button
                            on:click={openPopup(
                              item.id,
                              item.eventid,
                              item.category,
                              item.alertname
                            )}
                            class="ack-btn"
                            disabled={item.currentstatus === "In-active"}
                            >{ackBtnLabel}</button
                          >
                        {/if}
                      </td>
                    {:else if key === "ignore"}
                      <td class="sticky-class"
                        ><button
                          on:click={openDisableCommentPopup(
                            item.id,
                            item.eventid
                          )}
                          disabled={item.currentstatus === "In-active"}
                          class="ack-btn">{disableBtnLabel}</button
                        ></td
                      >
                    {:else if key === "alertname" && isAlertRedirectable}
                      <td
                        class="clickable-alert {getClassForCategory(
                          item[key]
                        )} sticky-class"
                        on:click={() => {
                          
                          alertNameClicked && alertNameClicked(item.nodeId);
                        }}
                        on:keypress={() => {
                          alertNameClicked && alertNameClicked(item.nodeId);
                        }}>{item[key]}</td
                      >
                    {:else if key === "alertvalue"}
                      <td class="{getClassForCategory(item[key])} sticky-class"
                        >{getAlertValueWithUnit(item[key], item["unit"])}</td
                      >
                    {:else}
                      <td class="{getClassForCategory(item[key])} sticky-class"
                        >{item[key]}</td
                      >
                    {/if}
                  {/if}
                {/if}
              {/each}
            </tr>
          {/each}
        {/if}
      </tbody>
    </table>
  </div>
  {#if Math.ceil(data?.length / itemsPerPage) > 1}
    <div class="pagination-class">
      <button
        class="filter-button"
        disabled={currentPage === 1}
        on:click={() => {
          gotoPage(1);
        }}><Icon icon="material-symbols:skip-previous" /></button
      >
      <button
        class="filter-button"
        disabled={currentPage === 1}
        on:click={prevPage}><Icon icon="grommet-icons:previous" /></button
      >
      <!-- {#each Array.from({ length: Math.ceil(data.length / itemsPerPage) }) as _, i}
    <button on:click={() => gotoPage(i + 1)}>{i + 1}</button>
  {/each} -->
      <!-- <button on:click={gotoPage(currentPage)}
    >{currentPage}/{Math.ceil(data.length / itemsPerPage)}</button
  > -->
      <div class="page-number">
        {currentPage}/{Math.ceil(data.length / itemsPerPage)}
      </div>
      <button
        class="filter-button"
        disabled={currentPage === Math.ceil(data.length / itemsPerPage)}
        on:click={nextPage}><Icon icon="grommet-icons:next" /></button
      >
      <button
        class="filter-button"
        disabled={currentPage === Math.ceil(data.length / itemsPerPage)}
        on:click={() => {
          gotoPage(Math.ceil(data.length / itemsPerPage));
        }}><Icon icon="material-symbols:skip-next" /></button
      >
    </div>
  {/if}
</div>

<style>
  .del-event-table-container {
    position: relative;
  }
  .body-container {
    display: grid;
  }

  button {
    cursor: pointer;
  }

  button:disabled {
    cursor: not-allowed;
  }

  .checkbox-div {
    display: flex;
    font: normal normal 500 14px/19px Roboto;
    padding-bottom: 10px;
  }

  .checkbox-left {
    justify-content: start;
  }

  .checkbox-right {
    justify-content: end;
  }

  .table-container {
    /* width: 100%; */
    overflow-x: auto;
    min-height: 350px;
  }

  .event-table {
    /* border-collapse: collapse; */
    width: 100%;
    /* color: #585859; */
    border-spacing: 0;
  }

  .event-table td.sticky-class:first-child,
  .event-table th.sticky-class:first-child {
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -o-sticky;
    position: -ms-sticky;
    position: sticky;
    left: 0;
    z-index: 1;
    opacity: 1;
    /* min-width: 250px;
    width: 250px; */
  }

  .th-parent {
    justify-content: center;
  }
  .event-table th,
  .event-table td {
    min-width: 100px;
    text-align: center;
  }
  .event-table th:first-child {
    border-left: 1px solid #707070;
  }

  .event-table th:last-child {
    border-right: 1px solid #707070;
  }

  .event-table td:first-child {
    border-left: 1px solid #ccd3e7;
  }

  .event-table td:last-child {
    border-right: 1px solid #ccd3e7;
  }

  .clickable-alert {
    cursor: pointer;
    text-decoration: underline;
    color: #488eff;
  }

  .date-and-time {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .date-div {
    text-align: left;
  }

  .time-div {
    text-align: center;
    font-weight: normal;
  }

  .title-row {
    top: 154px;
    left: 27px;
    width: 1385px;
    height: 38px;
    background: #fff3e5 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 40px 40px 0px 0px;
    text-align: left;
    font: normal normal 500 14px/19px Roboto;
    letter-spacing: 0px;
    color: #585859;
    opacity: 1;
  }

  tr {
    border: 1px solid #ccd3e7;
    opacity: 1;
  }

  .table-text {
    text-align: left;
    font: normal normal 500 14px/19px Roboto;
    letter-spacing: 0px;
    color: #585859;
    opacity: 1;
  }

  .nodata-text {
    width: 100%;
    text-align: center;
    margin-top: 10%;
    font: italic normal normal 16px/21px Roboto;
  }

  td {
    padding: 10px;
    background-color: white;
    opacity: 1;
    border-bottom: 1px solid #ccd3e7;
    font-weight: 500;
    /* min-width: 200px; */
    /* width: 200px; */
  }

  th {
    padding: 10px;
    background-color: #fff3e5;
    opacity: 1;
    border-top: 1px solid #707070;
    border-bottom: 1px solid #707070;
    color: #585859;
    /* min-width: 210px; */
    /* width: 200px; */
  }

  th:first-child {
    width: 250px;
  }

  .th-parent {
    align-items: center;
    display: flex;
    position: relative;
  }

  .critical-color {
    color: red;
  }

  .warning-color {
    color: #fcb900;
  }

  .informational-color {
    color: green;
  }

  .ack-btn {
    top: 220px;
    left: 1245px;
    width: 126px;
    height: 32px;
    background: #c3c8d6 0% 0% no-repeat padding-box;
    border: 1px solid #8b8b8b;
    border-radius: 4px;
    opacity: 1;
  }

  .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-width: 768px;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .popup-content {
    width: 35%;
    background: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }

  .popup-header {
    display: flex;
    justify-content: space-between;
    font: normal normal bold 16px/21px Roboto;
    color: #000000;
  }

  .alert-details {
    font: normal normal 700 14px/19px Roboto;
    text-align: end;
  }

  .popup-footer {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    align-items: center;
  }

  .popup-sub-title {
    color: rgb(94, 94, 94);
    display: flex;
    align-items: center;
    justify-content: space-between;
    font: normal normal normal 15px Roboto;
    padding: 5px 0;
  }

  .comment-textarea {
    width: 100%;
    padding: 8px 12px;
    border: 0;
    border-bottom: 1px;
    height: 88px;
    font-size: 13px;
    border-radius: 2px;
    box-sizing: border-box;
    margin: 0 0 0.5em 0;
    font-family: inherit;
  }

  .comment-text {
    padding: 10px 0;
    word-break: break-all;
    font: normal normal normal 15px Roboto;
  }

  .popup-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .close-btn {
    color: rgba(229, 31, 31);
    font: normal normal 500 14px/19px Roboto;
    padding: 10px;
    cursor: pointer;
    float: right;
  }
  .submit-btn:disabled {
    background-color: rgb(175, 195, 255);
    cursor: not-allowed;
  }
  .submit-btn {
    background-color: rgb(22, 58, 170);
    color: rgb(255, 255, 255);
    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    border-radius: 5px;
    border: none;
    padding: 5px 8px;
    font: normal normal 500 14px Roboto;
    cursor: pointer;
  }

  .title {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font: normal normal 700 14px/19px Roboto;
    padding-right: 1ch;
  }

  .filter-dropdown {
    position: relative;
    display: inline-block;
  }

  .filter-button {
    cursor: pointer;
    background: transparent;
    border: none;
    margin: 0;
    padding-top: 3px;
  }

  .filter-button:disabled {
    cursor: not-allowed;
  }

  .th-title {
    padding-right: 2px;
  }

  .dropdown-content {
    display: block;
    position: absolute;
    top: 50px;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 2;
    text-align: left;
  }

  .dropdown-content label {
    display: block;
    padding: 4px 8px;
  }

  .pagination-class {
    display: flex;
    justify-content: center;
    padding-top: 15px;
  }

  .child-node-checkbox {
    height: 15px;
    width: 15px;
  }

  .footer-text {
    font: normal normal normal 15px Roboto;
  }
</style>
