<script>
  import { selectedNode, selectedDate, allocatedScreenNames ,profileParentNode} from "../stores";
  import { getDomainURL, invokeAPI } from "../invokeAPI";
  import { ENVIRONMENT } from "../../environment";
  import { isSameAsToday, formatTimeStamp } from "./../utilityFunctions";
  import {
    pathNameSSPA,
    screensToShow,
    co2RealTimeLabel,
    tempRealTimeLabel,
    humidityRealTimeLabel,
    batteryRealTimeLabel,
    trendConfiguration,
  } from "./../../config";
  import DelProfileCard from "./../components/DelProfileCard/DelProfileCard.svelte";
  import DelRealtimeParameterCard from "./../components/DelRealtimeParameterCard/DelRealtimeParameterCard.svelte";
  import DelAlertCount from "./../components/DelAlertCount/DelAlertCount.svelte";
  import DelMultiAxisChart from "./../components/DelMultiAxisChart/DelMultiAxisChart.svelte";
  import Icon from "@iconify/svelte";

  const pathPrefix = ENVIRONMENT === "preview" ? "" : pathNameSSPA;

  let previousStartTime = null;
  let previousEndTime = null;

  $: {
    if ($selectedDate) {
      const start = $selectedDate?.dayDefinedStartDateInSeconds;
      let end = $selectedDate?.dayDefinedEndDateInSeconds;
      const endNotGreaterThanNow =
        $selectedDate?.dayDefinedEndDateInSecondsNotGreaterThanNow;
      const duration = end - start;
      previousStartTime = start - duration;
      if (isSameAsToday($selectedDate?.endDateInEpoch)) {
        previousEndTime = endNotGreaterThanNow - duration;
      } else {
        previousEndTime = end - duration;
      }
    }
  }

  $: parentNode = $selectedNode?.nodesInPath[0];

  let selectedAlarmArea = "All Alarms";
  let updatedTime = 0;

  $: if ($selectedNode) updatedTime = 0;

  const navigateToEventsPage = function (category) {
    if (selectedAlarmArea === "All Alarms")
      sessionStorage.setItem("saved-node-id", parentNode?.id);

    let filters = {
      currentstatus: ["Active"],
    };
    if (category) {
      filters.category = [category];
    }
    sessionStorage.setItem(
      "eventViewerSelectedFilters",
      JSON.stringify(filters)
    );
    window.location.href = `${pathPrefix}/AlarmViewer`;
  };

  function setHighestTimestamp(value) {
    if (!isNaN(updatedTime)) {
      updatedTime = Math.max(updatedTime, value);
    }
  }
</script>

<div class="del-page-container">
  <div class="del-left-container">
    <div class="left-profile-container">
      <DelProfileCard
        baseURL={getDomainURL()}
        currentScreen={"Dashboard"}
        selectedNodeId={$profileParentNode?.id}
        selectedNodeName={$profileParentNode?.name}
        displayImage={true}
      />
    </div>
    <div class="alarm-widget-div">
      <div class="alarm-tab-div">
        <button
          class={`alarm-tab-button ${
            selectedAlarmArea === "All Alarms" ? "selected" : ""
          }`}
          on:click={() => {
            selectedAlarmArea = "All Alarms";
          }}
        >
          All Alarms
        </button>
        <button
          class={`alarm-tab-button ${
            selectedAlarmArea === "Room Alarms" ? "selected" : ""
          }`}
          on:click={() => {
            selectedAlarmArea = "Room Alarms";
          }}
        >
          Room Alarms
        </button>
      </div>
      {#if selectedAlarmArea === "All Alarms"}
        <DelAlertCount
          baseURL={getDomainURL()}
          isRealTime={true}
          selectedNodeDetails={$profileParentNode}
          currentNodeId={parentNode?.id}
          uniqueId={"parentAlertCount"}
          title={""}
          viewAllClicked={navigateToEventsPage}
          categoryClicked={navigateToEventsPage}
          isViewAll={$allocatedScreenNames?.includes(
            screensToShow["/Reports"]?.screen
          )}
        />
      {:else}
        <DelAlertCount
          baseURL={getDomainURL()}
          isRealTime={true}
          selectedNodeDetails={$selectedNode}
          currentNodeId={$selectedNode?.id}
          title={""}
          uniqueId={"childAlertCount"}
          viewAllClicked={navigateToEventsPage}
          categoryClicked={navigateToEventsPage}
          isViewAll={$allocatedScreenNames?.includes(
            screensToShow["/AlarmViewer"]?.screen
          )}
        />
      {/if}
    </div>
  </div>
  <div class="del-right-container">
    <div class="del-top-right-container">
      <div class="right-profile-container">
        <DelProfileCard
          baseURL={getDomainURL()}
          currentScreen={"Dashboard"}
          selectedNodeId={$selectedNode?.id}
          selectedNodeName={$selectedNode?.name}
          displayImage={false}
        />
      </div>
      <div class="realtime-parameter-container">
        <div class="realtime-parameter-header">
          <div class="widget-title">CO2</div>
          <div class="realtime-latest-time">
            <Icon
              icon="icon-park-outline:time"
              color="#79919a"
              width="14"
              height="14"
            />
            {#if updatedTime}
              <label
                title={formatTimeStamp(updatedTime, "DD-MMM-YYYY hh:mm:ss")}
              >
                {formatTimeStamp(updatedTime, "DD-MMM-YYYY hh:mm:ss")}
              </label>{/if}
          </div>
        </div>
        <div class="parameter-card-container">
          <div class="co2-realtime-parameter">
            <DelRealtimeParameterCard
              baseURL={getDomainURL()}
              currentScreen={"Dashboard"}
              nodeDetails={$selectedNode}
              parameterType={co2RealTimeLabel.parameterType}
              parameterCategory={co2RealTimeLabel.parameterCategory}
              deviceCategory={co2RealTimeLabel.deviceCategory}
              readingTitle={co2RealTimeLabel.readingTitle}
              readingUnit={co2RealTimeLabel.readingUnit}
              uniqueID={co2RealTimeLabel.uniqueID}
              updateTimestamp={setHighestTimestamp}
            />
          </div>
          <div class="temp-realtime-parameter">
            <DelRealtimeParameterCard
              baseURL={getDomainURL()}
              currentScreen={"Dashboard"}
              nodeDetails={$selectedNode}
              parameterType={tempRealTimeLabel.parameterType}
              parameterCategory={tempRealTimeLabel.parameterCategory}
              deviceCategory={tempRealTimeLabel.deviceCategory}
              readingTitle={tempRealTimeLabel.readingTitle}
              readingUnit={tempRealTimeLabel.readingUnit}
              uniqueID={tempRealTimeLabel.uniqueID}
              updateTimestamp={setHighestTimestamp}
            />
          </div>
          <div class="humidity-realtime-parameter">
            <DelRealtimeParameterCard
              baseURL={getDomainURL()}
              currentScreen={"Dashboard"}
              nodeDetails={$selectedNode}
              parameterType={humidityRealTimeLabel.parameterType}
              parameterCategory={humidityRealTimeLabel.parameterCategory}
              deviceCategory={humidityRealTimeLabel.deviceCategory}
              readingTitle={humidityRealTimeLabel.readingTitle}
              readingUnit={humidityRealTimeLabel.readingUnit}
              uniqueID={humidityRealTimeLabel.uniqueID}
              updateTimestamp={setHighestTimestamp}
            />
          </div>
          <div class="battery-realtime-parameter">
            <DelRealtimeParameterCard
              baseURL={getDomainURL()}
              currentScreen={"Dashboard"}
              nodeDetails={$selectedNode}
              parameterType={batteryRealTimeLabel.parameterType}
              parameterCategory={batteryRealTimeLabel.parameterCategory}
              deviceCategory={batteryRealTimeLabel.deviceCategory}
              readingTitle={batteryRealTimeLabel.readingTitle}
              readingUnit={batteryRealTimeLabel.readingUnit}
              uniqueID={batteryRealTimeLabel.uniqueID}
              updateTimestamp={setHighestTimestamp}
            />
          </div>
        </div>
      </div>
    </div>
    <div class="del-bottom-right-container">
      <div class="trend-chart-container">
        <DelMultiAxisChart
          baseURL={getDomainURL()}
          currentScreen={"Dashboard"}
          nodeDetails={$selectedNode}
          parameterDetails={trendConfiguration.parameterDetails}
          downloadFileName={trendConfiguration.downloadFileName}
          showErrorPercentage={trendConfiguration.showErrorPercentage}
          showAnnotations={trendConfiguration.showAnnotations}
          errorLabel={trendConfiguration.errorLabel}
          errorParameterCategory={trendConfiguration.errorParameterCategory}
          annotationsList={trendConfiguration.annotationsList}
          title={trendConfiguration.title}
          chartHeight={trendConfiguration.chartHeight}
          chartColors={trendConfiguration.chartColors}
          uniqueID={trendConfiguration.uniqueID}
          dateTimeFormatForSingleDay={trendConfiguration.dateTimeFormatForSingleDay}
          dateTimeFormat={trendConfiguration.dateTimeFormat}
          legendOffSetX={-100}
        />
      </div>
    </div>
  </div>
</div>

<style>
  .del-page-container {
    background-color: #f7f8fa;
    padding: 18px 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    flex-wrap: wrap;
  }

  .del-left-container {
    width: 25%;
    display: flex;
    gap: 15px;
    flex-direction: column;
    height: 700px;
  }

  .left-profile-container {
    box-sizing: border-box;
    min-height: 490px;
  }

  .del-right-container {
    display: flex;
    flex-direction: column;
    width: calc(75% - 15px);
    height: 700px;
  }

  .del-top-right-container {
    display: flex;
    justify-content: space-between;
    min-height: 175px;
    flex-grow: 1;
    margin-bottom: 15px;
    gap: 15px;
  }

  .right-profile-container {
    width: calc(62% - 15px);
  }

  .realtime-parameter-container {
    background-color: white;
    border-radius: 5px;
    box-shadow: 5px 1px 7px #00000014;
    flex-grow: 1;
  }

  .alarm-widget-div {
    padding-bottom: 7px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 5px 1px 7px #00000014;
    /* min-height: 175px; */
    flex-grow: 1;
  }

  .alarm-tab-div {
    display: flex;
    gap: 15px;
    padding: 15px;
    padding-bottom: 0px;
    justify-content: center;
  }

  .alarm-tab-button {
    cursor: pointer;
    border: 1px solid #ccd3e7;
    border-radius: 6px;
    font: normal normal 600 14px/19px Roboto;
    color: #222222;
    background-color: white;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    gap: 10px;
    transition: all 0.5s;
  }

  .alarm-tab-button.selected {
    background-color: #f37a25;
    border: 1px solid #f37a25;
    color: white;
  }

  .realtime-parameter-header {
    display: flex;
    justify-content: space-between;
  }

  .widget-title {
    font: normal normal bold 18px / 24px Roboto;
    font-weight: 700 !important;
    padding: 10px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
    color: #61656c;
    position: relative;
  }

  .realtime-latest-time {
    font-size: 13px;
    color: #61656c;
    font-family: robotoregular, Arial, sans-serif;
    padding: 10px;
    display: flex;
    gap: 3px;
  }

  .parameter-card-container {
    display: flex;
    justify-content: space-around;
  }

  .del-bottom-right-container {
    background-color: white;
    border-radius: 5px;
    box-shadow: 5px 1px 7px #00000014;
  }

  .trend-chart-container {
    min-height: 490px;
  }

  :global(.widget-normal) {
    height: max-content !important;
  }
</style>
