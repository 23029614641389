<script>
  import toast, { Toaster } from "svelte-french-toast";
  import Loader from "../../components/Loader/Loader.svelte";
  import DelSlider from "./DelSlider.svelte";
  import DelLegends from "./../DelLegends/DelLegends.svelte";
  import Icon from "@iconify/svelte";
  export let baseURL;
  export let currentScreen = "Dashboard";
  export let NodeID;
  export let rangeWidgetConfiguration;
  export let tabOptions;
  export let isWritePermission = null;

  // varibles of Rangeslider
  let unit;
  let disableRangeSlider = false;
  let alertItemInformational = {};
  let alertItemWarning;
  let alertItemCritical;
  let start, end;
  let legendInfo = [];
  let readingLow = null;
  let readingHigh = null;
  let rangeWidgetConfigurationForRangeSelector = {};
  // variables of contact of alert
  let isEditing = false;
  let showPopUp = false;
  let isAlertApiWaiting = false;
  let isContactApiWaiting = false;
  let showTableBody = false;
  let buttonDisabled = false;
  let showDeletePopup = false;
  let payload = null;
  let alertId = [];
  let displayList = [];
  let slaResponse = [];
  let cslaResponse = [];
  let islaResponse = [];
  let wslaResponse = [];
  let paginatedData = [];
  let contactIdsToDelete = [];
  let tempResponseData;
  let currentPage = 1;
  let totalPages = 0;
  let entriesPerPage = 5;
  let responseData;
  let deleteEmail;
  let deleteCategory;
  let activeTab = localStorage.getItem("activeTab") || "CO2M";
  let formData = {
    Email: "",
    Mobile: "",
    Name: "",
    Category: [],
  };
  function formDataInitialize() {
    formData = {
      Email: "",
      Mobile: "",
      Name: "",
      Category: [],
    };
  }

  let error = {
    Name: "",
    Mobile: "",
    Email: "",
  };
  function errorInitialization() {
    error = {
      Name: "",
      Mobile: "",
      Email: "",
    };
  }

  $: {
    totalPages = Math.ceil(displayList.length / entriesPerPage);
    updatePagination();
  }
  $: responseData;
  $: endpoint = `alert/api/Configuration/${NodeID}/alertconfig`;

  $: {
    if (baseURL && currentScreen && NodeID) {
      payload = null;
      invokeAlertConfig();
    }
  }
  //Tab functions
  function handleTabClick(event) {
    activeTab = event.target.textContent.trim();
    if (activeTab === "CO2") activeTab = "CO2M";
    localStorage.setItem("activeTab", activeTab);
    updateSLAResponse();
  }
  //RangeSlider function
  const setLegendText = function (data) {
    if (
      !data.Informational?.AlertRule &&
      !data.Critical?.AlertRule &&
      !data.Warning?.AlertRule
    ) {
      return [];
    }

    let arrayofLegends = [];
    unit =
      activeTab === "CO2M" || activeTab === "H2O2"
        ? "PPM"
        : activeTab === "Temperature"
          ? "°C"
          : "%";

    const getKey = function (property) {
      let title = "";
      let string = "";
      let obj = data[property];

      if (property === "Normal") {
        if (activeTab === "Temperature") {
          let value1 = data.Informational?.AlertRule?.min;
          let value2 = data.Warning?.AlertRule?.max;

          string =
            value1 && value2
              ? " Between " + value2 + unit + " and " + value1 + unit
              : "";
        } else if (activeTab === "Humidity") {
          let value1 = data?.Informational?.AlertRule?.max;
          let value2 = data?.Warning?.AlertRule?.min;
          string =
            value1 && value2
              ? " Between " + value1 + unit + " and " + value2 + unit
              : "";
          title = "Left knob - Right knob";
        } else {
          let value = data.Informational?.AlertRule?.min;
          value = value ? value : data.Informational?.AlertRule?.value;
          string = value ? " Below " + value + unit : "";
          title = "Left knob";
        }
      }
      if (obj.AlertRule) {
        switch (obj.AlertRule.Operation) {
          case "InRange":
            string =
              " Between " +
              obj.AlertRule.min +
              unit +
              " and  " +
              obj.AlertRule.max +
              unit;
            break;
          case "GreaterThanOrEqual":
            string = " More than and equal to " + obj.AlertRule.value + unit;
            break;
          case "GreaterThan":
            string = " More than " + obj.AlertRule.value + unit;
            break;
          case "LessThan":
            string = " Less than " + obj.AlertRule.value + unit;
            break;
          case "NotInRange":
            string =
              " Below " +
              obj.AlertRule.min +
              unit +
              " and above " +
              obj.AlertRule.max +
              unit;
            break;
          default:
            string += " " + obj.AlertRule.value + unit;
        }
      }
      let getMathVal = (num) => {
        return num >= 0 ? " + " + num + " " : " - " + Math.abs(num) + " ";
      };
      switch (property) {
        case "Normal":
          switch (activeTab) {
            case "Temperature":
            case "Humidity":
              title = "Between Left knob and Right knob";
              break;
            case "CO2M":
              title = "Below Left knob";
              break;
            case "H2O2":
              title = "Below Left knob";
              break;

            default:
              break;
          }
          break;
        case "Informational":
          switch (activeTab) {
            case "Temperature":
              title =
                "Between Right knob and (Right knob" +
                getMathVal(rangeWidgetConfiguration["Temperature"].rightBuff) +
                ")";

              break;
            case "Humidity":
              title =
                "Between (Left knob" +
                getMathVal(rangeWidgetConfiguration["Humidity"].leftBuff) +
                ") and Left knob";
              break;
            case "CO2M":
              title =
                "Between Left knob and (Left knob" +
                getMathVal(rangeWidgetConfiguration["CO2M"].alarmbuffer) +
                ")";
              break;
            case "H2O2":
              title =
                "Between Left knob and (Left knob" +
                getMathVal(rangeWidgetConfiguration["H2O2"].alarmbuffer) +
                ")";
              break;

            default:
              break;
          }
          break;
        case "Critical":
          switch (activeTab) {
            case "Temperature":
              title =
                "Below (Left knob" +
                getMathVal(rangeWidgetConfiguration["Temperature"].leftBuff) +
                ") and above (Right knob" +
                getMathVal(rangeWidgetConfiguration["Temperature"].rightBuff) +
                ")";
              break;
            case "Humidity":
              title =
                "Below (Left knob" +
                getMathVal(rangeWidgetConfiguration["Humidity"].leftBuff) +
                ") and above (Right knob" +
                getMathVal(rangeWidgetConfiguration["Humidity"].rightBuff) +
                ")";
              break;
            case "CO2M":
              title = "Above Right knob";
              break;
            case "H2O2":
              title = "Above Right knob";
              break;

            default:
              break;
          }
          break;
        case "Warning":
          switch (activeTab) {
            case "Temperature":
              title =
                "Between (Left knob" +
                getMathVal(rangeWidgetConfiguration["Temperature"].leftBuff) +
                ") and Left knob";
              break;
            case "Humidity":
              title =
                "Between Right knob and (Right knob" +
                getMathVal(rangeWidgetConfiguration["Humidity"].rightBuff) +
                ")";
              break;
            case "CO2M":
              title =
                "Between (Left knob" +
                getMathVal(rangeWidgetConfiguration["CO2M"].alarmbuffer) +
                ") and Right knob";
              break;
            case "H2O2":
              title =
                "Between (Left knob" +
                getMathVal(rangeWidgetConfiguration["H2O2"].alarmbuffer) +
                ") and Right knob";
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
      return {
        value:
          string === "" ? null : "(" + property + ") % of Total Time" + string,
        title,
      };
    };
    if (getKey("Normal").value)
      arrayofLegends.push({
        title: getKey("Normal").value,
        tooltip: getKey("Normal").title,
        color: "#109e46",
      });
    if (getKey("Informational").value)
      arrayofLegends.push({
        title: getKey("Informational").value,
        tooltip: getKey("Informational").title,
        color: "#92d050",
      });
    if (getKey("Warning").value)
      arrayofLegends.push({
        title: getKey("Warning").value,
        tooltip: getKey("Warning").title,
        color: "#fdc500",
      });
    if (getKey("Critical").value)
      arrayofLegends.push({
        title: getKey("Critical").value,
        tooltip: getKey("Critical").title,
        color: "#fa3838",
      });
    return arrayofLegends;
  };

  const findRangesAndLegendText = function () {
    let data = {
      Informational: {
        AlertRule: alertItemInformational?.AlertRule,
      },
      Warning: { AlertRule: alertItemWarning?.AlertRule },
      Normal: { AlertRule: null },
      Critical: { AlertRule: alertItemCritical?.AlertRule },
    };

    const getoperationText = (operationId) => {
      switch (operationId) {
        case 1:
          return "EqualTo";
        case 2:
          return "LessThanOrEqual";

        case 3:
          return "GreaterThanOrEqual";

        case 4:
          return "LessThan";

        case 5:
          return "GreaterThan";

        case 6:
          return "NotEqual";

        case 8:
          return "NotInRange";

        case 7:
          return "InRange";

        default:
          return "";
      }
    };
    if (data.Informational.AlertRule)
      data.Informational.AlertRule.Operation = getoperationText(
        data.Informational.AlertRule?.operation
      );

    if (data.Warning.AlertRule)
      data.Warning.AlertRule.Operation = getoperationText(
        data.Warning.AlertRule?.operation
      );
    if (data.Critical.AlertRule)
      data.Critical.AlertRule.Operation = getoperationText(
        data?.Critical.AlertRule?.operation
      );
    legendInfo = setLegendText(data);

    rangeWidgetConfigurationForRangeSelector = {
      ...rangeWidgetConfiguration[activeTab],
    };
  };
  function getSliderInitialValues() {
    let co2Calc, h2o2Calc;
    switch (activeTab) {
      case "Temperature":
        readingHigh = alertItemInformational?.AlertRule.min;
        readingLow = alertItemWarning?.AlertRule.max;
        break;
      case "Humidity":
        readingLow = alertItemInformational?.AlertRule.max;
        readingHigh = alertItemWarning?.AlertRule.min;
        break;
      case "CO2M":
        co2Calc = alertItemInformational?.AlertRule.max;
        readingLow = alertItemInformational?.AlertRule.min;
        co2Calc = alertItemWarning?.AlertRule.min;
        readingHigh = alertItemWarning?.AlertRule.max;

        break;
      case "H2O2":
        h2o2Calc = alertItemInformational?.AlertRule.max;
        readingLow = alertItemInformational?.AlertRule.min;
        h2o2Calc = alertItemWarning?.AlertRule.min;
        readingHigh = alertItemWarning?.AlertRule.max;
        break;
    }
  }
  const onSaveButtonClick = function (start, end) {
    let lowCalc = start + rangeWidgetConfigurationForRangeSelector?.leftBuff;
    let highCalc = end + rangeWidgetConfigurationForRangeSelector?.rightBuff;
    //only for co2
    let co2Calc = start + rangeWidgetConfigurationForRangeSelector?.alarmbuffer;
    //only for h2o2
    let h2o2Calc =
      start + rangeWidgetConfigurationForRangeSelector?.alarmbuffer;
    switch (activeTab) {
      case "Temperature":
        alertItemInformational.AlertRule.max = highCalc;
        alertItemInformational.AlertRule.min = end;

        alertItemWarning.AlertRule.min = lowCalc;
        alertItemWarning.AlertRule.max = start;

        alertItemCritical.AlertRule.min = lowCalc;
        alertItemCritical.AlertRule.max = highCalc;
        break;
      case "Humidity":
        alertItemInformational.AlertRule.max = start;
        alertItemInformational.AlertRule.min = lowCalc;

        alertItemWarning.AlertRule.min = end;
        alertItemWarning.AlertRule.max = highCalc;

        alertItemCritical.AlertRule.min = lowCalc;
        alertItemCritical.AlertRule.max = highCalc;
        break;
      case "CO2M":
        alertItemInformational.AlertRule.max = co2Calc;
        alertItemInformational.AlertRule.min = start;

        alertItemWarning.AlertRule.min = co2Calc;
        alertItemWarning.AlertRule.max = end;

        alertItemCritical.AlertRule.value = end;
        break;
      case "H2O2":
        alertItemInformational.AlertRule.max = h2o2Calc;
        alertItemInformational.AlertRule.min = start;

        alertItemWarning.AlertRule.min = h2o2Calc;
        alertItemWarning.AlertRule.max = end;

        alertItemCritical.AlertRule.value = end;
        break;
    }

    let newItem = responseData.find(
      (item) => item.AlertId === alertItemCritical.AlertId
    );

    newItem = { ...alertItemCritical };

    newItem = responseData.find(
      (item) => item.AlertId === alertItemInformational.AlertId
    );
    newItem = { ...alertItemInformational };

    newItem = responseData.find(
      (item) => item.AlertId === alertItemWarning.AlertId
    );
    newItem = { ...alertItemWarning };
    isAlertApiWaiting = true;
    addUpdate("Alert", "Update");
  };

  const sliderChange = function (from, to) {
    start = from;
    end = to;
  };

  //function of Contact of Alert

  // Pagination function
  const updatePagination = function () {
    if (currentPage > totalPages && currentPage != 1)
      currentPage = currentPage - 1;

    const startIndex = (currentPage - 1) * entriesPerPage;
    const endIndex = startIndex + entriesPerPage;
    paginatedData = displayList.slice(startIndex, endIndex);
  };

  function handlePrevPage() {
    currentPage--;
    updatePagination();
  }
  function handleNextPage() {
    currentPage++;
    updatePagination();
  }

  function invokeAlertConfig() {
    isAlertApiWaiting = true;
    isContactApiWaiting = true;
    invokeAPI("GET", endpoint, `${currentScreen}/R`)
      .then((data) => {
        onSuccess && onSuccess(data);
      })
      .catch((error) => {
        isContactApiWaiting = false;
        isAlertApiWaiting = false;
        console.log("API failed ", error);
      });
  }

  function updateSLAResponse() {
    slaResponse = responseData?.filter((value) => {
      return value.SLACategory === activeTab && value.AlertRule;
    });
    cslaResponse = slaResponse?.filter((value) => {
      return value.Category === "Critical";
    });
    wslaResponse = slaResponse?.filter((value) => {
      return value.Category === "Warning";
    });
    islaResponse = slaResponse?.filter((value) => {
      return value.Category === "Informational";
    });

    alertItemInformational = slaResponse?.find(({ Category }) => {
      return Category === "Informational";
    });
    alertItemCritical = slaResponse?.find(({ Category }) => {
      return Category === "Critical";
    });
    alertItemWarning = slaResponse?.find(({ Category }) => {
      return Category === "Warning";
    });
    if (
      !(
        alertItemInformational &&
        alertItemCritical &&
        alertItemWarning &&
        isWritePermission
      )
    ) {
      disableRangeSlider = false;
    } else {
      disableRangeSlider = true;
    }

    findRangesAndLegendText();
    updatePagination();
    getSliderInitialValues();
    setTableContent();
  }

  let onSuccess = function (data) {
    tempResponseData = data;
    responseData = data;
    isAlertApiWaiting = false;
    isContactApiWaiting = false;
    updateSLAResponse();
  };

  const invokeAPI = async function (
    method,
    endpoint,
    accessOrigin,
    extraHeader = {}
  ) {
    const companyId = localStorage.getItem("companyId");
    const applicationId = sessionStorage.getItem("appId");
    const accessToken = localStorage.getItem("access_token");
    if (!(accessToken && applicationId && companyId && accessOrigin))
      throw new Error("Missing required credentials");
    let headers = {
      ...extraHeader,
      companyId: companyId,
      applicationId: applicationId,
      "access-origin": accessOrigin,
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };
    let options = { method, headers };
    if (payload) {
      options.body = JSON.stringify(payload);
    }

    const response = await fetch(`${baseURL}/${endpoint}`, options);

    const data = await response.json();

    if (!response?.ok) {
      throw new Error(data.message);
    }
    return data;
  };
  function getToastOnFail(msg) {
    toast.error(`${msg}`, {
      style: "background: #FF4633; color:#ffff ;border:none;",
      position: "bottom-right",
      iconTheme: { primary: "#fff", secondary: "#FF4633" },
    });
  }

  async function addUpdate(functionality, process) {
    let options = process === "Add" ? "Added" : `${process}d`;
    showDeletePopup = false;
    let updateUrl = "alert/api/Configuration/all";
    payload = responseData;
    if (baseURL && currentScreen && NodeID) {
      try {
        showPopUp = false;

        const res = await invokeAPI("POST", updateUrl, `${currentScreen}/W`);

        if (res) {
          displayTableBody();
          payload = null;

          invokeAPI("GET", endpoint, `${currentScreen}/R`)
            .then((data) => {
              if (data)
                toast.success(`${functionality} ${options} Successfully`, {
                  style: "background: #56c465; color:#ffff ;border:none;",
                  position: "bottom-right",
                  iconTheme: { primary: "#fff", secondary: "#56c465" },
                });
              onSuccess && onSuccess(data);
            })
            .catch(() => {
              getToastOnFail(` Failed to ${process} ${functionality}`);
              isContactApiWaiting = false;
              isAlertApiWaiting = false;
            });

          isEditing = false;
        } else {
          throw new Error();
        }
      } catch (error) {
        getToastOnFail(` Failed to ${process} ${functionality}`);
        responseData = tempResponseData;
        payload = null;
        isContactApiWaiting = false;
        isAlertApiWaiting = false;
        console.log(`API failed`);
      }
    }
  }

  function validateInput(field) {
    let regex;
    if (field === "Name") {
      regex = /^[A-Za-z][A-Za-z0-9_ ]{2,29}$/;
    } else if (field === "Mobile") {
      regex = /^(\s*|\+[0-9]{10,15})$/;
    } else {
      regex = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
    }

    if (formData[field].match(regex)) error[field] = false;
    else error[field] = true;
  }

  function isNotEmptyObject() {
    for (let key in formData) {
      if (formData.hasOwnProperty(key)) {
        if (Array.isArray(formData[key])) {
          if (formData[key].length === 0) return false;
        } else if (formData[key] === null || formData[key] === undefined) {
          return false;
        }
      }
    }
    return true;
  }

  function handleSubmit() {
    if (!error.Name && !error.Mobile && !error.Email && isNotEmptyObject()) {
      formData.Category.forEach((Category) => {
        if (Category === "Informational") {
          alertId = islaResponse?.map((value) => {
            return value.AlertId;
          });
        } else if (Category === "Warning") {
          alertId = wslaResponse?.map((value) => {
            return value.AlertId;
          });
        } else {
          alertId = cslaResponse?.map((value) => {
            return value.AlertId;
          });
        }
        if (isEditing) {
          deleteRow(contactIdsToDelete);
        }

        alertId.forEach((res) => {
          let edit = responseData.find((r) => r.AlertId === res);
          edit.Contacts.push(formData);
        });
      });

      if (alertId != null) {
        isContactApiWaiting = true;
        addUpdate("Contact", `${isEditing ? "Update" : "Add"}`);
      }
    }
    contactIdsToDelete = [];
  }

  function handleEdit(id, category) {
    isEditing = true;
    showPopUp = true;
    contactIdsToDelete = [];
    const contactData = responseData.flatMap((item) => {
      return item.Contacts;
    });
    let contact = contactData.find((c) => {
      return c.Id == id;
    });
    formData = {
      Name: contact?.Name || "",
      Mobile: contact?.Mobile || "",
      Email: contact?.Email || "",
      Category: category,
    };

    findId(formData.Email, formData.Category);
  }

  function findId(email, category) {
    let ids = [];
    let arr = [];
    category.forEach((c) => {
      if (c === "Informational") {
        arr = islaResponse;
      } else if (c === "Warning") {
        arr = wslaResponse;
      } else if (c === "Critical") {
        arr = cslaResponse;
      }
      arr.forEach((response) => {
        response.Contacts.forEach((contact) => {
          if (contact.Email == email) {
            ids.push(contact.Id);
          }
        });
      });
    });

    contactIdsToDelete = ids;
    if (!isEditing) deleteRow(contactIdsToDelete);
  }

  function deleteRow(id) {
    id.forEach((i) => {
      responseData.forEach((response) => {
        let index = response.Contacts.findIndex((contact) => {
          return contact.Id == i;
        });
        if (index != -1) {
          response.Contacts.splice(index, 1);
        }
      });
    });

    if (!isEditing) {
      isContactApiWaiting = true;
      addUpdate("Contact", "Delete");
    }
  }

  function displayTableBody() {
    showTableBody = !showTableBody;
    buttonDisabled = !buttonDisabled;
    formDataInitialize();
  }

  function generateKey(name, mobile, email) {
    let compositeKey = `${name}${mobile}${email}`;
    return compositeKey;
  }

  function setTableContent() {
    let combinedArray = [];
    let keyGeneratedContacts = [];
    let notNullContacts = slaResponse.filter(
      (item) => item.Contacts.length !== 0
    );

    notNullContacts.forEach((item) => {
      item.Contacts.forEach((i) => {
        const contact = {
          Id: i.Id,
          Name: i.Name,
          Mobile: i.Mobile,
          Email: i.Email,
          Category: [item.Category],
          key: generateKey(i.Name, i.Mobile, i.Email),
        };
        keyGeneratedContacts.push(contact);
      });
    });
    keyGeneratedContacts.forEach((item) => {
      let index = combinedArray.findIndex((el) => el.key === item.key);
      if (index != -1) {
        if (
          combinedArray[index].Category.findIndex(
            (el) => el == item.Category[0]
          ) == -1
        ) {
          combinedArray[index].Category.push(item.Category[0]);
        }
      } else {
        combinedArray.push(item);
      }
    });
    displayList = keyGeneratedContacts.filter(
      (contact, index, self) =>
        index === self.findIndex((c) => c.key === contact.key)
    );
  }
</script>

<svelte:head>
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
  <link
    href="https://fonts.googleapis.com/css2?family=Roboto&display=swap"
    rel="stylesheet"
  />
</svelte:head>

<div class="del-alarm-container">
  <Toaster />

  <!-- Del Tab -->
  <div class="del-tab">
    {#each tabOptions as tab}
      <button
        class={activeTab === tab ? " default-button active" : "default-button"}
        on:click={handleTabClick}
      >
        {tab == "CO2M" ? "CO2" : tab}
      </button>
    {/each}
  </div>

  <!-- Del Alarm Slider -->

  <div class="del-range-selector">
    {#if isAlertApiWaiting}
      <Loader />
    {/if}
    <div class="del-range-selector-heading heading">
      <label for="">{rangeWidgetConfigurationForRangeSelector.title} </label>
    </div>
    <div class="del-range-slider">
      <DelSlider
        sliderProperty={rangeWidgetConfigurationForRangeSelector}
        isDisabled={disableRangeSlider}
        {sliderChange}
        from={readingLow ? readingLow : "0"}
        to={readingHigh ? readingHigh : "0"}
      />
    </div>
    <div class="del-range-legend" style="height: auto;">
      <DelLegends legends={legendInfo} />
    </div>
    <div style="float: right;">
      <button
        class="default-button mainbutton"
        disabled={!disableRangeSlider}
        on:click={() => onSaveButtonClick(start, end)}>Save</button
      >
    </div>
  </div>

  <!-- Del Contact Notification -->

  <div class="del-alarm-notification">
    {#if isContactApiWaiting}
      <Loader />
    {/if}
    <div class="container-header">
      <div class="heading">
        <label for="">Contacts for Alarm Notification</label>
      </div>
      <div class="footer">
        <button
          disabled={isWritePermission ? false : true}
          title="Add"
          class="default-button mainbutton"
          on:click={() => {
            showPopUp = true;
            isEditing = false;
            formDataInitialize();
          }}
          >+ Add New
        </button>
      </div>
    </div>

    <div class="del-alarm-form">
      <div class="table-container">
        <table>
          <thead>
            <tr class="header">
              <th>S.NO</th>
              <th>NAME</th>
              <th>MOBILE NO</th>
              <th>EMAIL</th>
              <th>CATEGORY</th>
              {#if isWritePermission}
                <th>ACTION</th>
              {/if}
            </tr>
          </thead>

          <tbody class="table-body">
            {#if displayList.length === 0}
              <tr class="noDataFound">
                <td colspan={isWritePermission ? "6" : "5"}>
                  No Contact found
                </td>
              </tr>
            {:else}
              {#each paginatedData as i, index}
                <tr class=" del-alerts-table" id={index}>
                  <td>{index + 1}</td>
                  <td>{i?.Name}</td>
                  <td>{i?.Mobile}</td>
                  <td>{i?.Email}</td>
                  <td>{i?.Category} </td>
                  {#if isWritePermission}
                    <td
                      ><span>
                        <button
                          class="table-button"
                          on:click={(e) => handleEdit(i.Id, i.Category)}
                          ><Icon
                            icon="material-symbols:edit-outline"
                            style="font-size: 24px; color: #61656c;"
                          /></button
                        >
                        <button
                          class="table-button"
                          on:click={() => {
                            deleteEmail = i.Email;
                            deleteCategory = i.Category;
                            showDeletePopup = true;
                          }}
                          ><Icon
                            icon="material-symbols:delete-outline"
                            style="font-size: 24px; color: #61656c;"
                          /></button
                        >
                      </span>
                    </td>{/if}
                </tr>
              {/each}
            {/if}
            {#if showDeletePopup}
              <div class="del-alarm-popup">
                <div class="deleteWrapper">
                  <div class="del-popup-heading">
                    <div style="display:flex; align-items:flex-end;">
                      <Icon
                        icon="material-symbols:delete-outline"
                        style="font-size:35px; margin:10px;"
                      />
                      <h1 style="font-size:20px ">Delete Contact</h1>
                    </div>
                    <span
                      class="close"
                      on:click={() => {
                        showDeletePopup = false;
                      }}
                      ><Icon
                        icon="mingcute:close-fill"
                        style="font-size:24px; position:relative;float:right"
                      /></span
                    >
                  </div>
                  <div style="padding: 1em ; font-size:18px">
                    <p>Are you sure you want to delete this?</p>
                  </div>
                  <div class="delete-popup-button">
                    <button
                      on:click={() => findId(deleteEmail, deleteCategory)}
                      class="default-button mainbutton">Delete</button
                    >
                    <button
                      on:click={() => (showDeletePopup = false)}
                      class="default-button mainbutton">Cancel</button
                    >
                  </div>
                </div>
              </div>{/if}
            {#if showPopUp}
              <div class="del-alarm-popup">
                <div class="del-alarm-popup-wrapper">
                  <div class="popup-header">
                    <h1
                      style="color: #cf661b;margin-bottom:20px; font-size: 20px;"
                    >
                      {isEditing ? `Update` : " Add"} Contact
                    </h1>
                    <span
                      class="close"
                      on:click={() => {
                        showPopUp = false;
                        errorInitialization();
                        contactIdsToDelete = [];
                      }}
                      ><Icon
                        icon="mingcute:close-fill"
                        style="font-size:24px"
                      /></span
                    >
                  </div>

                  <form class="form">
                    <div class="form-element">
                      <label class="popup-label required" for="name"
                        >Name:</label
                      >
                      <input
                        class="popup-input"
                        id="Name"
                        name="Name"
                        bind:value={formData.Name}
                        on:input={() => validateInput("Name")}
                        required
                        type="text"
                        placeholder="Enter name..."
                      />
                      {#if error.Name}
                        <p class="error">Enter a valid Name</p>
                      {/if}
                    </div>
                    <div class="form-element">
                      <label class="popup-label" for="phn">Mobile Number:</label
                      >
                      <input
                        class="popup-input"
                        id="Mobile"
                        name="Mobile"
                        type="text"
                        bind:value={formData.Mobile}
                        on:input={() => validateInput("Mobile")}
                        placeholder="Eg: +1234567899..."
                      />
                      {#if error.Mobile}
                        <p class="error">Enter a valid Mobile Number</p>
                      {/if}
                    </div>
                    <div class="form-element">
                      <label class="popup-label required" for="email"
                        >Email:</label
                      >
                      <input
                        class="popup-input"
                        required
                        type="text"
                        id="Email"
                        name="Email"
                        bind:value={formData.Email}
                        placeholder="Enter email..."
                        on:input={() => validateInput("Email")}
                      />
                      {#if error.Email}
                        <p class="error">Enter a valid Email id</p>
                      {/if}
                    </div>
                    <div class="form-element">
                      <label class="popup-label required" for="category"
                        >Category:</label
                      >
                      <div class="category-input">
                        <input
                          class="popup-input-checkbox"
                          value="Informational"
                          bind:group={formData.Category}
                          id="Informational"
                          type="checkbox"
                          name="Informational"
                        />
                        <label for="Informational">Informational</label>

                        <input
                          class="popup-input-checkbox"
                          value="Warning"
                          bind:group={formData.Category}
                          id="Warning"
                          type="checkbox"
                          name="Warning"
                        />
                        <label for="Warning">Warning</label>

                        <input
                          class="popup-input-checkbox"
                          value="Critical"
                          bind:group={formData.Category}
                          id="Critical"
                          type="checkbox"
                          name="Critical"
                        />
                        <label for="Critical">Critical</label>
                      </div>
                    </div>
                    <div class="popup-button">
                      <button
                        class="default-button mainbutton"
                        type="submit"
                        on:click={() => {
                          handleSubmit();
                        }}>{isEditing ? `Update` : " Add"}</button
                      ><button
                        on:click={() => {
                          showPopUp = false;
                          errorInitialization();
                          contactIdsToDelete = [];
                        }}
                        class="default-button mainbutton">Cancel</button
                      >
                    </div>
                  </form>
                </div>
              </div>
            {/if}
          </tbody>
        </table>
      </div>
      <!-- Pagination -->
      {#if displayList.length > entriesPerPage}
        <div class="pagination-container">
          <div class="pagination-btn">
            <button
              class="prev-button"
              disabled={currentPage === 1}
              on:click={handlePrevPage}
              ><Icon icon="mingcute:left-fill" style="font-size:24px" />
            </button>
            <span class="current-page">Page {currentPage} of {totalPages}</span>
            <button
              class="next-button"
              disabled={currentPage === totalPages}
              on:click={handleNextPage}
            >
              <Icon icon="mingcute:right-fill" style="font-size:24px" /></button
            >
          </div>
        </div>
      {/if}
    </div>
  </div>
</div>

<style>
  /* Main Container */
  .del-alarm-container {
    padding: 10px;
    margin: 20px;
    overflow: hidden;
    font: normal normal bold 14px/19px Roboto;
    box-shadow:
      rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    background-color: white !important;
  }
  /* del-range-selector */

  .del-range-legend {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: px;
    padding: 20px;
    margin-top: 60px;
  }
  .del-range-selector {
    position: relative;
    overflow: hidden;
    margin: 10px;
    margin-bottom: 30px;
    padding: 20px;
    background-color: white;
    box-shadow:
      rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  }

  /* Del tab */
  .del-tab {
    padding: 10px;
    border-bottom: 1px solid #f0f0f0;
    display: flex;
  }
  .del-tab button {
    margin: 0.2em 0;
    margin-right: 1em;
  }
  .active {
    background-color: #f37a25 !important;
    color: white !important;
    border: 1px solid #f37a25;
  }

  /* Del Contact of Alarm Notification */
  .del-alarm-notification {
    position: relative;
    margin: 10px;
    background-color: white;
    padding: 20px;
    box-shadow:
      rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  }
  .container-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  .heading {
    font-size: 14px;
    color: #61656c;
  }
  .category-input {
    display: flex;
    gap: 5px;
  }
  .del-alarm-form {
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.08);
  }
  .del-alarm-popup {
    background-color: rgba(0, 0, 0, 0.396);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 11;
  }
  .del-popup-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5em 0;
  }
  .popup-header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
  .deleteWrapper {
    background-color: white;
    margin: 9% auto;
    padding: 1.5rem;
    max-width: 35vw;
    min-height: 10vw;
    border-radius: 20px;
    overflow: hidden;
  }
  .del-alarm-popup-wrapper {
    background-color: white;
    margin: 10% auto;
    padding: 2rem;
    max-width: 35vw;
    border-radius: 20px;
  }
  .delete-popup-button {
    margin: 10px;
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: end;
    gap: 10px;
  }
  .close {
    color: #695b5b6d;
    float: right;
    cursor: pointer;
    font-size: 35px;
  }
  .close:hover {
    color: #353232cd;
  }
  .form-element {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1em;
    align-items: flex-start;
    width: 90%;
  }
  .form-element label {
    font-size: 14px;
  }
  .popup-label {
    color: #cf661b;
    font-size: 14px;
    display: block;
    margin-bottom: 5px;

    padding-right: 0.5em;
    display: inline-block;
    width: 100%;
    font-size: 14px;
  }
  .required:after {
    content: "  *";
    color: red;
  }
  .error {
    padding: 0;
    color: red;
    margin: 0;
  }
  .popup-input {
    --border-color: #31a2f4;
    --border-width: 2px;
    --bottom-distance: 0px;

    background: transparent;
    border: none;
    border-bottom: 2px solid #2e2b2b57;

    font-size: 14px;
    background-image: linear-gradient(var(--border-color), var(--border-color));
    background-size: 0% var(--border-width);
    background-repeat: no-repeat;
    transition: background-size 0.8s;
    background-position: 50% calc((100% - var(--bottom-distance)));
    width: 100%;
  }
  .popup-input:hover {
    background-size: 100% var(--border-width);
    border: none;
  }

  .popup-button {
    display: flex;
    justify-content: end;
    padding: 2px;
    gap: 10px;
  }
  .popup-button button {
    padding: 5px 15px;
  }
  .popup-input-checkbox {
    margin-left: 10px;
    width: 17px;
    height: 17px;
  }
  .default-button {
    cursor: pointer;
    border: 1px solid #ccd3e7;
    border-radius: 6px;
    font: normal normal 600 14px/19px Roboto;
    color: #222222;
    background-color: #f7f8fa;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    gap: 8px;
    transition: all 0.5s;
  }
  .mainbutton:hover:enabled {
    background-color: #f37a25;
    color: white !important;
  }

  .table-container {
    overflow: auto;
    position: relative;
  }
  table {
    font-size: 14px;
    border-collapse: collapse;
    max-width: 100%;
    position: relative;
    overflow: hidden;
    min-width: 120vb;
    color: #61656c;
    width: 100%;
    table-layout: fixed;
    margin-bottom: 0 !important;
    padding: 5px 10px;
  }
  thead {
    color: #fff;
    background-color: #acb2b6;
    font-size: 18px;
  }
  tr {
    height: 35px;
    border-bottom: 1px solid #f0f0f0;
    display: table-row;
  }
  .del-alerts-table:hover {
    background: #f1f9ff;
    border-color: rgba(0, 0, 0, 0.05);
  }
  th {
    padding-left: 15px;

    text-align: left;
    font-weight: 400;
    border-bottom-width: 1px;
  }
  td {
    display: table-cell;
    position: relative;
    text-align: left;
    vertical-align: middle;
    padding: 5px;
    padding-left: 18px;
    font-size: 14px;
  }
  tbody {
    overflow: auto;
    width: 100%;
    height: 100%;
  }

  input:focus {
    outline: none;
  }
  .table-button {
    background: none;
    border: none;
    cursor: pointer;
  }

  .noDataFound td {
    padding: 0.5em 2em;
    text-align: center;
  }

  .footer {
    padding: 0.5em 0;
    background-color: #fff;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    display: flex;
    justify-content: flex-end;
  }

  /* Pagination */
  .pagination-container {
    padding: 10px;
  }
  .pagination-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #61656c;
  }
  .pagination-btn button {
    border: none;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background: #ffffff;
    cursor: pointer;
    margin: 0 1.5em;
    padding: 5px;
    font-weight: 1000;
    border-radius: 5px;
    text-align: center;
  }

  button:disabled {
    background: #b6b6b66f;
    cursor: not-allowed;
    color: #b6b6b66f;
  }
</style>
