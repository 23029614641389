<script>
  import moment from "moment";
  import ApexCharts from "apexcharts";
  import Loader from "./../../Loader/Loader.svelte";
  import { onMount } from "svelte";

  export let baseURL;
  export let endPoint;
  export let currentScreen = "Reports";
  export let nodeId;
  export let startTime;
  export let endTime;
  export let includeIgnored;
  export let slaCategory;
  export let alertCategoryOrder;
  export let unit = "";
  export let xAxisFormat;
  export let chartHeight = "350px";
  export let chartColors = ["#109E46", "#92D050", "#FDC500", "#FA3838"];
  export let legendPosition = "bottom";

  let payload;
  let isDataAwaiting = false;
  let isNoData = false;
  let dataSeries = [];
  let xData = [];
  let chart = null;
  let chartElement = null;
  let barWidth;

  const getHeader = function () {
    const companyId = localStorage.getItem("companyId");
    const appId = sessionStorage.getItem("appId");
    const access_token = "Bearer " + localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      companyid: companyId,
      applicationid: appId,
      Authorization: access_token,
      "access-origin": `${currentScreen}/R`,
    };
    return headers;
  };

  $: if (
    nodeId &&
    baseURL &&
    currentScreen &&
    startTime &&
    endTime &&
    endPoint &&
    (includeIgnored || !includeIgnored) &&
    slaCategory
  ) {
    clearChart();
    fetchData();
  }

  onMount(() => {
    clearChart();
    fetchData();
  });

  async function fetchData() {
    try {
      const url = `${baseURL}/parameters/api/SLAReport/${endPoint}`;

      payload = {
        EndTime: endTime,
        NodeId: nodeId,
        SLACategory: slaCategory,
        StartTime: startTime,
        IncludeIgnored: includeIgnored,
      };

      if (
        !(
          payload.StartTime &&
          payload.EndTime &&
          payload.NodeId &&
          payload.SLACategory
        )
      )
        return;

      const options = {
        method: "POST",
        headers: getHeader(),
        body: JSON.stringify(payload),
      };

      isDataAwaiting = true;
      const response = await fetch(url, options);
      isDataAwaiting = false;

      if (!response.ok) {
        let error = await response.json();
        throw new Error(error);
      }

      const data = await response.json();
      fetchDataOnSuccess(data);
    } catch (error) {
      isDataAwaiting = false;
      console.error(error);
    }
  }

  function fetchDataOnSuccess(data) {
    let unsortedData = {};
    dataSeries = [];
    xData = [];
    let normalRange = {};
    let timestamps = [];

    Object.entries(data).forEach(([category, reading]) => {
      let values = [];
      reading["Data"]?.forEach((item) => {
        timestamps.push(item["day"]);
        values.push(item["PercentageValue"].toFixed(2));
      });

      if (category === "Warning") {
        normalRange["Min"] = reading["AlertRule"]?.Max;
      } else if (category === "Informational")
        normalRange["Max"] = reading["AlertRule"]?.Min;

      if (category === "Normal") {
        if (slaCategory === "CO2M") {
          unsortedData[category] = {
            name: `% of Total Time Below ${normalRange["Max"]}${unit} `,
            data: values,
          };
        } else {
          unsortedData[category] = {
            name: `% of Total Time Between ${normalRange["Min"]}${unit} And ${normalRange["Max"]}${unit}`,
            data: values,
          };
        }
      } else if (category === "Critical") {
        if (slaCategory === "CO2M") {
          unsortedData[category] = {
            name: `% of Total Time More Than ${reading["AlertRule"]?.Value}${unit} `,
            data: values,
          };
        } else {
          unsortedData[category] = {
            name: `% of Total Time Below ${reading["AlertRule"]?.Min}${unit} And Above ${reading["AlertRule"]?.Max}${unit}  `,
            data: values,
          };
        }
      } else
        unsortedData[category] = {
          name: `% of Total Time Between ${reading["AlertRule"]?.Min}${unit} And ${reading["AlertRule"]?.Max}${unit}`,
          data: values,
        };
    });

    alertCategoryOrder.forEach((key) => {
      if (unsortedData[key]) {
        dataSeries.push(unsortedData[key]);
      }
    });

    formatDateTime(timestamps);
    checkForNoData();
    if (chart) {
      updateChart();
    } else {
      plotChart();
    }
  }

  function formatDateTime(epoch) {
    let timestamps = [...new Set(epoch)];
    timestamps.forEach((item) => {
      let date = moment(item * 1000);
      let formattedDate = date.format(xAxisFormat);
      xData.push(formattedDate);
    });
  }

  function checkForNoData() {
    isNoData = true;
    for (let i = 0; i < dataSeries.length; i++) {
      if (dataSeries[i].data.length > 0) {
        isNoData = false;
        break;
      }
    }
  }

  function plotChart() {
    barWidth =
      dataSeries.length && dataSeries[0].data.length
        ? dataSeries[0].data.length * 7 > 70
          ? 70
          : dataSeries[0].data.length * 7
        : 70;

    if (chartElement) {
      const options = {
        series: dataSeries,
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          stackType: "100%",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: `${barWidth}%`,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        xaxis: {
          categories: xData,
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              // Format the value here (e.g., add commas, change units)
              return value.toFixed(2); // Example: Display with 2 decimal places
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: legendPosition,
          onItemClick: {
            toggleDataSeries: false,
          },
        },
        colors: chartColors,

        tooltip: {
          intersect: false,
          shared: true,
          y: {
            formatter: function (value) {
              return value;
            },
          },
        },
      };

      chart = new ApexCharts(chartElement, options);
      chart.render();
    }
  }

  function updateChart() {
    barWidth =
      dataSeries.length && dataSeries[0].data.length
        ? dataSeries[0].data.length * 7 > 70
          ? 70
          : dataSeries[0].data.length * 7
        : 70;

    if (chart) {
      chart.updateOptions({
        series: dataSeries,
        xaxis: {
          categories: xData,
        },
        plotOptions: {
          bar: {
            columnWidth: `${barWidth}%`,
          },
        },
      });
    }
  }

  function clearChart() {
    dataSeries = [];
    xData = [];

    if (chart) {
      updateChart();
    } else {
      plotChart();
    }
  }
</script>

<div class="main-container">
  {#if isDataAwaiting}
    <Loader />
  {/if}
  <div class="body-div">
    {#if isNoData && !isDataAwaiting}
      <div class="no-data">No data available</div>
    {/if}
    <div
      style={`${chartHeight ? `height:${chartHeight}` : ""}`}
      bind:this={chartElement}
    />
  </div>
</div>

<style>
  .main-container {
    position: relative;
  }

  .body-div {
    position: relative;
  }

  .no-data {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 40%;
    font: italic normal normal 16px/21px Roboto;
  }

  :global(.apexcharts-active) {
    fill: transparent;
  }
</style>
