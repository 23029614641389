import * as XLSX from "xlsx";
import { delpheonLogo } from "./delpheonLogo";
import moment from "moment/moment";

export function exportExcel(
  fileType,
  fileName,
  startTime,
  endTime,
  nodeName,
  reportData,
  reportName = "Report"
) {
  let workbook = XLSX.utils.book_new();
  for (const node of Object.keys(reportData)) {
    const columnHeadings = reportData[node].length
      ? Object.keys(reportData[node][0])
      : [];
    let headerArray = [
      [`${reportName} of ${nodeName}`],
      [""],
      ["Date", `From : ${startTime}`, "", "", `To : ${endTime}`],
      ["Customer", localStorage.getItem("companyName")],
      [`Report of ${node}`],
      [""],
    ];

    let worksheet = XLSX.utils.aoa_to_sheet(headerArray);

    XLSX.utils.sheet_add_json(worksheet, reportData[node], {
      header: columnHeadings,
      origin: -1,
    });

    const merge = [
      {
        s: {
          r: 0,
          c: 0,
        },
        e: {
          r: 0,
          c: 4,
        },
      },
      {
        s: {
          r: 1,
          c: 1,
        },
        e: {
          r: 1,
          c: 4,
        },
      },
      {
        s: {
          r: 1,
          c: 5,
        },
        e: {
          r: 1,
          c: 8,
        },
      },
      {
        s: {
          r: 2,
          c: 1,
        },
        e: {
          r: 2,
          c: 3,
        },
      },
      {
        s: {
          r: 3,
          c: 1,
        },
        e: {
          r: 3,
          c: 4,
        },
      },
      {
        s: {
          r: 4,
          c: 0,
        },
        e: {
          r: 4,
          c: 4,
        },
      },
    ];

    worksheet["!merges"] = merge;

    XLSX.utils.book_append_sheet(workbook, worksheet, node.slice(0, 31));
  }
  const exportFileName = `${fileName} from ${startTime} to ${endTime}.${fileType}`;
  return XLSX.writeFile(workbook, exportFileName);
}

export function exportCsv(
  fileType,
  fileName,
  startTime,
  endTime,
  nodeName,
  reportData,
  reportName = "Report"
) {
  let columnHeadings = [];

  if (reportData.length) {
    columnHeadings = Object.keys(reportData[0]);
  }

  let workbook = XLSX.utils.book_new();

  const headerArray = [
    [reportName],
    [""],
    ["Date", `From : ${startTime}`, "", "", `To : ${endTime}`],
    ["Customer", localStorage.getItem("companyName")],
    [`Report of ${nodeName}`],
    [""],
  ];

  let worksheet = XLSX.utils.aoa_to_sheet(headerArray);

  XLSX.utils.sheet_add_json(worksheet, reportData, {
    header: columnHeadings,
    origin: -1,
  });

  const merge = [
    {
      s: {
        r: 0,
        c: 0,
      },
      e: {
        r: 0,
        c: 4,
      },
    },
    {
      s: {
        r: 1,
        c: 1,
      },
      e: {
        r: 1,
        c: 4,
      },
    },
    {
      s: {
        r: 1,
        c: 5,
      },
      e: {
        r: 1,
        c: 8,
      },
    },
    {
      s: {
        r: 2,
        c: 1,
      },
      e: {
        r: 2,
        c: 3,
      },
    },
    {
      s: {
        r: 3,
        c: 1,
      },
      e: {
        r: 3,
        c: 4,
      },
    },
    {
      s: {
        r: 4,
        c: 0,
      },
      e: {
        r: 4,
        c: 4,
      },
    },
  ];

  worksheet["!merges"] = merge;

  XLSX.utils.book_append_sheet(workbook, worksheet, "Raw Report");

  const exportFileName = `${fileName} from ${startTime} to ${endTime}.${fileType}`;
  return XLSX.writeFile(workbook, exportFileName);
}

export function generatePDF(
  startTime,
  endTime,
  nodeName,
  reportData,
  reportName = "Report",
  numberOfColumns = 0,
  landscapeNumber = 8
) {
  let applicationName = sessionStorage.getItem("appName");
  applicationName = applicationName ? applicationName.toUpperCase() : "";

  /*------------------------------------ PDF Basic Styling ----------------------------------------------*/
  const htmlForPDFHeader = `<html><head><style>@media print{*{-webkit-print-color-adjust: exact !important; /* Chrome, Safari 6 – 15.3, Edge */ color-adjust: exact !important; /* Firefox 48 – 96 */ print-color-adjust: exact !important; /* Firefox 97+, Safari 15.4+ */}
      @page {
     size: landscape letter;
  }body{size: ${
    numberOfColumns > landscapeNumber ? "landscape" : "portrait"
  }; width: ${numberOfColumns > landscapeNumber ? "29.7cm" : "21cm"}; height:${
    numberOfColumns > landscapeNumber ? "21cm" : "29.7cm"
  } ; margin:auto; /* change the margins as you want them to be. */}.page-number{  content: counter(page)}.delpheon-header{font-size: 15px;}.t{padding:10px 3px; text-align:center;}.counter:after{counter-increment: page 1;}.pageNumber{content: counter(page);}.t2{padding:10px 3px; text-align:center;}.t:first-child{border-left:1px solid #E7EBED}.t:last-child{border-right:1px solid #E7EBED}table{page-break-after:auto}tr{page-break-inside:avoid; page-break-after:auto}td{page-break-inside:avoid; page-break-after:auto}thead{display:table-header-group}tfoot{display:table-footer-group}.delpheon-band{top: 88px;left: 0px;width: 100%;height: 20px;/* UI Properties */background: #F2F6F8 0% 0% no-repeat padding-box;opacity: 1;font:normal normal 12px/22px Roboto;letter-spacing: 0.4px;color: #5E72B1;text-transform: uppercase;opacity: 1;text-align:center;}h4{overflow:hidden;}h4:after{content:''; display:inline-block; width:100%; margin-right:-100%; border-bottom:3px solid #D5DFE2;}}.delpheon-header{font-size: 15px;}.t{padding:10px 3px; text-align:center;}table{page-break-after:auto;border-collapse:collapse;}tr{page-break-inside:avoid; page-break-after:auto}td{page-break-inside:avoid; page-break-after:auto}thead{display:table-header-group}tfoot{display:table-footer-group}.delpheon-band{top: 88px;left: 0px;width: 100%;height: 20px;/* UI Properties */background: #F2F6F8 0% 0% no-repeat padding-box;opacity: 1;font:normal normal 12px/22px Roboto;letter-spacing: 0.4px;color: #5E72B1;text-transform: uppercase;opacity: 1;text-align:center;}h4{overflow:hidden;}h4:after{content:''; display:inline-block; width:100%; margin-right:-100%; border-bottom:3px solid #D5DFE2;}</style><title>${reportName} </title></head>`;

  /*------------------------------------ PDF Header Styles ----------------------------------------------*/
  const bodyStart = `<body><div class='temp'><div class='delpheon-header'> <div style='position:relative; width: 100%;height: 88px;background: transparent linear-gradient(111deg, #FE7E24 0%, #203F9B 100%) 0% 0% no-repeat padding-box;'> <div style='top: 33px;position: absolute;left: 40px;width: 71px;height: 21px;background: transparent url(${delpheonLogo}) 0% 0% no-repeat padding-box;opacity: 1;'></div><div style='position: absolute;top:34px;left:120px;color:white;'>|</div><div style='position: absolute;top: 36px;left: 132px;height: 16px;text-align: left;font: italic normal 300 12px/16px Roboto;letter-spacing: 0.6px;color: #FFFFFF;text-transform: uppercase;opacity: 1;'>${reportName}</div><div style='position: absolute;right: 4%;top: 26%;'> <p style='text-align: right;font: normal normal normal 10px/13px Roboto;letter-spacing: 0px;color: #FFFFFF;'>Report Date : ${moment().format(
    "DD-MM-YYYY"
  )} </p><p style='text-align: right;font: normal normal normal 10px/13px Roboto; letter-spacing: 0px;color: #FFFFFF;'>India</p></div></div></div>${
    applicationName ? `<div class='delpheon-band'>${applicationName}</div>` : ""
  }<div style='background-color: #F2F6F8;margin-top:10px;border-radius:10px'> <div style='margin: 0 auto;text-align:center;font: normal normal medium 8px/10px Roboto;letter-spacing: 0.4px;color: #5E72B1;padding: 14px 10px 7px 10px;'>${localStorage.getItem(
    "companyName"
  )} ${reportName} <br>Report of ${nodeName}</div><div style='display:flex; gap:15px;padding: 14px 10px 7px 10px;'> <div style='margin: 0 auto;'><span style='font-size:12px'>Dates </span><span style=' color:black; font-size:12px'> From : ${startTime}</span><span style='font-size:12px'> </span><span style='color: black;font-size:12px;'>\xa0\xa0 To : ${endTime} </span></div></div></div><div id='headerline'> `;

  /*------------------------------- Line Separating table from summary -----------------------------------*/
  const textReportsWithLine =
    "<div style='margin-top:10px'> <h4 style='color: #3B478D;/* margin-left: 55px; */font-weight: 700;text-transform: uppercase;'>Reports</h4></div>";

  /*------------------------------------ Table Body ----------------------------------------------*/
  let tableContent = ``;

  for (const node in reportData) {
    const tableHeaderArray = reportData[node].length
      ? Object.keys(reportData[node][0])
      : [];
    const rows = reportData[node];

    // Individual table creation starts here

    let table = `<div style="margin:15px auto;">\
                    <div style="text-align:center;font: normal normal bold 18px Roboto;color: #5E72B1; margin-bottom:15px;">${node}</div>\
                    <div style='margin-left: auto;margin-right: auto; ' class='wrapper' >\
                        <table style="border-collapse:collapse;width:100%">\
                            <thead><tr style='border: 1px solid #ddd;color: black;background-color: #F2F6F8;height: 30px;font-family:Roboto;'>`; // Boiler plate table along with DATE header

    //Goes through tableheaderarray to create table headings excluding dates
    tableHeaderArray.forEach((item) => {
      table = table + `<th class='t2'>${item}</th>`;
    });
    table = table + "</tr></thead><tbody>";

    //Here Rows are created from rows array provided
    rows.forEach((item) => {
      table = table + "<tr style='border-bottom:1px solid #E7EBED'>"; //border bottom for each row
      tableHeaderArray.forEach((item2) => {
        if (item2) {
          let dat = "";
          if (!item[item2] && item[item2] !== 0) {
            //checking for no data
            table = table + `<td class='t'></td>`;
          } else {
            table = table + `<td class='t'> ${item[item2]}</td>`;
          }
        }
      });
      table = table + `</tr>`;
    });
    table = table + `</tbody></table></div>`;
    tableContent += table;
  }

  /*------------------------------------ Combining all to get pdf report -------------------------------*/
  const pdfContent =
    htmlForPDFHeader + bodyStart + textReportsWithLine + tableContent;
  printDiv(pdfContent);
}

function printDiv(contents) {
  let frame1 = document.createElement("iframe");
  frame1.name = "frame1";
  frame1.style.position = "absolute";
  frame1.style.top = "-1000000px";
  document.body.appendChild(frame1);
  let frameDoc = frame1.contentWindow
    ? frame1.contentWindow
    : frame1.contentDocument.document
    ? frame1.contentDocument.document
    : frame1.contentDocument;
  frameDoc.document.open();
  frameDoc.document.write(contents);
  frameDoc.document.close();
  setTimeout(function () {
    window.frames["frame1"].focus();
    window.frames["frame1"].print();
    document.body.removeChild(frame1);
  }, 500);
  return false;
}
