<script>
  export let legends = [];
  export let align = "center";
</script>

<div
  id="legend-container"
  class:align-left={align === "left"}
  class:align-right={align === "right"}
>
  {#each legends as item}
    <div class="legend-item" title={item.tooltip}>
      <div class="legend-circle" style="background : {item.color}"></div>
      <div class="legend-title">{item.title}</div>
    </div>
  {/each}
</div>

<style>
  #legend-container {
    width: inherit;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    font-size: 14px;
    padding: 8px 0;
  }
  #legend-container.align-left {
    justify-content: flex-start;
  }
  #legend-container.align-right {
    justify-content: flex-end;
  }
  .legend-item {
    margin: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }
  .legend-item:last-child {
    margin-right: 0;
  }
  .legend-title {
    padding-left: 10px;
    align-self: center;
    color: #61656c;
    font: normal normal 600 11px Roboto;
  }
  .legend-circle {
    border-radius: 50%;
    min-width: 12px;
    min-height: 12px;
    border: 1px solid #333;
    position: relative;
    transition: all 0.3s;
  }

  @media screen and (min-width: 900px) {
    #legend-container,
    #legend-container.align-left,
    #legend-container.align-right {
      justify-content: center;
    }
  }
</style>
