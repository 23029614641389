<script>
  import { applicationError } from "./../stores";
</script>

<div class="del-error-message">{$applicationError?.message || ""}</div>

<style>
  .del-error-message {
    position: absolute;
    top: 50%;
    width: 100%;
    text-align: center;
    font: normal normal 500 18px Roboto;
  }
</style>
