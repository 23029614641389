<script>
  import Icon from "@iconify/svelte";
  import DelPieChart from "./DelPieChart/DelPieChart.svelte";
  import DelDataTable from "./DelDataTable/DelDataTable.svelte";
  import DelDatePicker from "./../DelDatePicker/DelDatePicker.svelte";
  import DelStackedBarChartSla from "./DelStackedBarChartSLA/DelStackedBarChartSLA.svelte";
  import Switch from "./Switch.svelte";
  import moment from "moment";

  let downloading = false;
  export let baseURL;
  export let currentScreen;
  export let nodeDetails;
  let defaultStartDate = sessionStorage.getItem(
    "sla-report-selected-start-time"
  )
    ? sessionStorage.getItem("sla-report-selected-start-time")
    : null;
  let defaultEndDate = sessionStorage.getItem("sla-report-selected-end-time")
    ? sessionStorage.getItem("sla-report-selected-end-time")
    : null;
  export let showBackButton = true;
  export let onBackButtonClick;

  let pdfPayload = {
    EndTime: 0,
    NodeId: "",
    SLACategory: "",
    IncludeIgnored: true,
    StartTime: 0,
  };
  let isIgnoredAlarmsIncluded = true;
  let selectedCategory = sessionStorage.getItem("sla-selected-category")
    ? sessionStorage.getItem("sla-selected-category")
    : "CO2";
  let categoryUnit = sessionStorage.getItem("sla-selected-category-unit")
    ? sessionStorage.getItem("sla-selected-category-unit")
    : "PPM";
  let startTime;
  let endTime;
  let selectedDate;
  let hourlyStartTime = moment().startOf("day").unix();
  let hourlyEndTime = moment().unix();
  let xAxisFormat;

  $: if (selectedCategory || isIgnoredAlarmsIncluded) {
    if (isGreaterThanStartOfToday(endTime)) endTime = moment().unix();
    hourlyEndTime = moment().unix();
  }

  const getHeader = function () {
    const companyId = localStorage.getItem("companyId");
    const appId = sessionStorage.getItem("appId");
    const access_token = "Bearer " + localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      companyid: companyId,
      applicationid: appId,
      Authorization: access_token,
      "access-origin": `${currentScreen}/R`,
    };
    return headers;
  };

  function isGreaterThanStartOfToday(date) {
    const today = moment().startOf("day").valueOf();

    return date * 1000 > today;
  }

  async function exportPDF() {
    downloading = true;
    try {
      const url = `${baseURL}/parameters/api/SLAReport/pdf`;

      pdfPayload = {
        EndTime: endTime,
        NodeId: nodeDetails?.id,
        SLACategory: selectedCategory === "CO2" ? "CO2M" : selectedCategory,
        IncludeIgnored: isIgnoredAlarmsIncluded,
        StartTime: startTime,
      };

      if (
        !(
          pdfPayload.StartTime &&
          pdfPayload.EndTime &&
          pdfPayload.NodeId &&
          pdfPayload.SLACategory
        )
      )
        return;

      const options = {
        method: "POST",
        headers: getHeader(),
        body: JSON.stringify(pdfPayload),
      };

      const response = await fetch(url, options);
      exportPDFOnSuccess(response);

      if (!response.ok) {
        let error = await response.json();
        throw new Error(error);
      }
    } catch (error) {
      downloading = false;
      console.error(error);
    }
  }

  async function exportPDFOnSuccess(response) {
    let content = await response.blob();

    const disposition = response.headers.get("content-disposition");
    let filename = null;
    if (disposition && disposition.indexOf("attachment") !== -1) {
      let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      let matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, "");
      }
    }

    let blob = new Blob([content]);

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob);
      return;
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(blob);
    let link = document.createElement("a");
    link.href = data;
    link.download = filename ? filename : "Template.pdf";
    document.body.appendChild(link);
    link.click();
    downloading = false;

    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      document.body.removeChild(link);
      window.URL.revokeObjectURL(data);
    }, 100);
  }

  function onDateChange(date) {
    if (date) {
      startTime = date.dayDefinedStartDateInSeconds;
      endTime = date.dayDefinedEndDateInSecondsNotGreaterThanNow;
      selectedDate = date.date;
      sessionStorage.setItem("sla-report-selected-start-time", date?.start);
      sessionStorage.setItem("sla-report-selected-end-time", date?.end);
    }
  }

  function setCategoryAndUnit(category, unit) {
    selectedCategory = category;
    categoryUnit = unit;
    sessionStorage.setItem("sla-selected-category", selectedCategory);
    sessionStorage.setItem("sla-selected-category-unit", categoryUnit);
  }

  function isLeapYear(year) {
    if ((year % 4 == 0 && year % 100 !== 0) || year % 400 == 0) {
      return true;
    } else {
      return false;
    }
  }
  function getTimePeriod(start, end) {
    const moment1 = moment.unix(start);
    const moment2 = moment.unix(end);
    const startYear = new Date(start * 1000).getFullYear();
    const endTimeStamp = end * 1000 - 1;
    const endYear = new Date(endTimeStamp).getFullYear();

    let daysDifference = moment2.diff(moment1, "days");

    if (isGreaterThanStartOfToday(end)) daysDifference += 1;

    if (daysDifference <= 31) {
      xAxisFormat = "DD MMM";
      return "Day";
    } else if (daysDifference > 31 && daysDifference <= 365) {
      xAxisFormat = "MMM 'YY";
      return "Month";
    } else if (
      daysDifference === 366 &&
      startYear === endYear &&
      isLeapYear(startYear)
    ) {
      xAxisFormat = "MMM 'YY";
      return "Month";
    } else {
      xAxisFormat = "YYYY";
      return "Year";
    }
  }
</script>

<svelte:head>
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
  <link
    href="https://fonts.googleapis.com/css2?family=Roboto&display=swap"
    rel="stylesheet"
  />
</svelte:head>

<div class="sla-report-container">
  <div class="sla-header-container">
    <div class="back-time-picker">
      {#if showBackButton}
        <div class="back-button-div">
          <button
            class="del-header-back-button"
            on:click={() => {
              onBackButtonClick && onBackButtonClick();
            }}><Icon icon="ep:back" width="128" height="128" /></button
          >
        </div>{/if}
      <div class="date-picker-div">
        <DelDatePicker
          onDateChanged={onDateChange}
          {defaultStartDate}
          {defaultEndDate}
          autoApply={true}
          format={"DD-MMMM-YYYY"}
          firstDay={1}
          customRangesToShow={[
            "Today",
            "Yesterday",
            "Last 7 days",
            "Last 30 days",
            "Current Week",
            "Last Week",
            "Current Month",
            "Last Month",
          ]}
        />
      </div>
    </div>

    <div class="tab-div">
      <button
        class={`category-selector-button ${
          selectedCategory === "CO2" ? "selected" : ""
        }`}
        on:click={() => {
          setCategoryAndUnit("CO2", "PPM");
        }}>CO2</button
      >
      <button
        class={`category-selector-button ${
          selectedCategory === "Temperature" ? "selected" : ""
        }`}
        on:click={() => {
          setCategoryAndUnit("Temperature", "°C");
        }}>Temperature</button
      >
      <button
        class={`category-selector-button ${
          selectedCategory === "Humidity" ? "selected" : ""
        }`}
        on:click={() => {
          setCategoryAndUnit("Humidity", "%");
        }}>Humidity</button
      >
    </div>
    <div class="toggle_download">
      <div class="toggle-button-div">
        Include Ignored Alarms
        <Switch bind:checked={isIgnoredAlarmsIncluded} />
      </div>

      <div class="download-button-div">
        <button class="download-button" on:click={exportPDF}>
          {#if downloading}
            Downloading...
          {:else}
            Download Report
          {/if}
        </button>
      </div>
    </div>
  </div>
  <div class="sla-body-container">
    <div class="body-row">
      <div class="pie-chart-container">
        <div class="title-div">Selected Range Data</div>
        <DelPieChart
          {baseURL}
          {currentScreen}
          {nodeDetails}
          {startTime}
          {endTime}
          IncludeIgnored={isIgnoredAlarmsIncluded}
          slaCategory={selectedCategory === "CO2" ? "CO2M" : selectedCategory}
          showLegend={true}
          custom={false}
          chartHeight={"350px"}
          sortOrder={["Normal", "Informational", "Warning", "Critical"]}
          selectColors={{
            Normal: "#59A047",
            Informational: "#92D050",
            Warning: "#F8C506",
            Critical: "#E64D36",
          }}
        />
      </div>
      <div class="data-table-container">
        <div class="title-div">Top Alarm Values</div>
        <DelDataTable
          {baseURL}
          {currentScreen}
          nodeId={nodeDetails?.id}
          {startTime}
          {endTime}
          includeIgnored={isIgnoredAlarmsIncluded}
          slaCategory={selectedCategory === "CO2" ? "CO2M" : selectedCategory}
          dataSelection={"topten"}
          columnTitles={["DATE/HOUR", `VALUE (${categoryUnit})`]}
        />
      </div>
      {#if selectedCategory !== "CO2"}
        <div class="data-table-container">
          <div class="title-div">Low Alarm Values</div>
          <DelDataTable
            {baseURL}
            {currentScreen}
            nodeId={nodeDetails?.id}
            {startTime}
            {endTime}
            includeIgnored={isIgnoredAlarmsIncluded}
            slaCategory={selectedCategory === "CO2" ? "CO2M" : selectedCategory}
            dataSelection={"lowestten"}
            columnTitles={["DATE/HOUR", `VALUE (${categoryUnit})`]}
          />
        </div>
      {/if}
      <div class="pie-chart-container">
        <div class="title-div">Selected Range Data (7AM to 7PM)</div>
        <DelPieChart
          {baseURL}
          {currentScreen}
          {nodeDetails}
          {startTime}
          {endTime}
          IncludeIgnored={isIgnoredAlarmsIncluded}
          slaCategory={selectedCategory === "CO2" ? "CO2M" : selectedCategory}
          showLegend={true}
          custom={true}
          chartHeight={"350px"}
          sortOrder={["Normal", "Informational", "Warning", "Critical"]}
          selectColors={{
            Normal: "#59A047",
            Informational: "#92D050",
            Warning: "#F8C506",
            Critical: "#E64D36",
          }}
        />
      </div>
    </div>
    <div class="body-graph-row">
      <div class="stacked-chart-div">
        <div class="title-div">
          Air Quality Per {getTimePeriod(startTime, endTime)}
        </div>
        <DelStackedBarChartSla
          {baseURL}
          {currentScreen}
          nodeId={nodeDetails?.id}
          {startTime}
          {endTime}
          includeIgnored={isIgnoredAlarmsIncluded}
          endPoint={"barchart"}
          slaCategory={selectedCategory === "CO2" ? "CO2M" : selectedCategory}
          alertCategoryOrder={[
            "Normal",
            "Informational",
            "Warning",
            "Critical",
          ]}
          unit={categoryUnit}
          {xAxisFormat}
          chartHeight={"350px"}
          chartColors={["#109E46", "#92D050", "#FDC500", "#FA3838"]}
          legendPosition={"bottom"}
        />
      </div>
    </div>
    <div class="body-graph-row">
      <div class="stacked-chart-div">
        <div class="title-div">
          Hourly Report of {moment().format("DD MMM YYYY")}
        </div>
        <DelStackedBarChartSla
          {baseURL}
          {currentScreen}
          nodeId={nodeDetails?.id}
          startTime={hourlyStartTime}
          endTime={hourlyEndTime}
          includeIgnored={isIgnoredAlarmsIncluded}
          endPoint={"hourly"}
          slaCategory={selectedCategory === "CO2" ? "CO2M" : selectedCategory}
          alertCategoryOrder={[
            "Normal",
            "Informational",
            "Warning",
            "Critical",
          ]}
          unit={categoryUnit}
          xAxisFormat={"hh a"}
          chartHeight={"350px"}
          chartColors={["#109E46", "#92D050", "#FDC500", "#FA3838"]}
          legendPosition={"bottom"}
        />
      </div>
    </div>
  </div>
</div>

<style>
  .sla-report-container {
    background-color: #f7f8fa;
  }

  .sla-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    flex-wrap: wrap;
    gap: 10px;
  }

  .back-time-picker {
    display: flex;
    gap: 20px;
  }
  .date-picker-div {
    width: fit-content;
  }

  .tab-div {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    top: 3px;
    position: relative;
  }

  .category-selector-button {
    min-height: 38px;
    min-width: 114px;
    cursor: pointer;
    border: 1px solid #ccd3e7;
    border-radius: 6px;
    font: normal normal 600 14px/19px Roboto;
    color: #222222;
    background-color: white;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    gap: 10px;
    transition: all 0.5s;
    height: 30px;
  }

  .category-selector-button.selected {
    background-color: #f37a25;
    border: 1px solid #f37a25;
    color: white;
  }

  .category-selector-button:hover {
    background-color: #d5d3d3;
  }

  .selected:hover {
    background-color: #f37a25;
  }
  .toggle_download {
    display: flex;
    flex-direction: row;
    gap: 15px;
    flex-wrap: wrap;
  }
  .toggle-button-div {
    font-weight: 600 !important;
    font-size: 12px;
    color: #504f4f;
    font-family: "Roboto", sans-serif;
    display: flex;
    gap: 5px;
    align-items: center;
  }

  .download-button {
    cursor: pointer;
    border: 1px solid #f37a25;
    border-radius: 6px;
    font: normal normal 600 14px/19px Roboto;
    color: #222222;
    background-color: white;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    gap: 10px;
    transition: all 0.5s;
    min-height: 38px;
  }

  .download-button:hover {
    background-color: #d5d3d3;
  }

  .body-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    background-color: white;
    box-shadow: 5px 1px 7px #00000014;
    padding: 20px;
    margin: 10px;
    min-height: 210px;
    gap: 20px;
  }

  .pie-chart-container {
    min-width: 250px;
  }

  .body-graph-row {
    background-color: white;
    box-shadow: 5px 1px 7px #00000014;
    padding: 10px;
    margin: 10px;
    min-height: 410px;
  }

  .title-div {
    color: #504f4f !important;
    font-weight: 700 !important;
    margin-bottom: 5px !important;
    padding: 0 !important;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    text-align: center;
  }

  .back-button-div {
    height: 37px;
  }

  .del-header-back-button {
    background: #f37a25 0 0 no-repeat padding-box;
    border: 1px solid #f37b25ae;
    color: #ffffff;
    opacity: 1;
    border-radius: 64%;
    width: 37px;
    height: 37px;
    display: flex;
    font-size: 30px;
    align-items: center;
    padding: 7px;
  }

  .del-header-back-button:enabled:hover,
  .del-header-back-button:enabled:active,
  .del-header-back-button:enabled:focus {
    transform: scale(1.05);
    cursor: pointer;
  }
</style>
