<script>
  import { onMount } from "svelte";
  import Loader from "./../../Loader/Loader.svelte";
  import ApexCharts from "apexcharts";

  export let baseURL;
  export let nodeDetails;
  export let startTime = 0;
  export let endTime = 0;
  export let currentScreen = "Dashboard";
  export let IncludeIgnored = false;
  export let slaCategory = "";
  export let legendPosition = "bottom";
  export let legendAlignment = "left";
  export let showLegend;
  export let chartHeight = "800px";
  export let custom;
  export let sortOrder = ["Normal", "Informational", "Warning", "Critical"];
  export let selectColors = {
    Normal: "#59A047",
    Informational: "#92D050",
    Warning: "#F8C506",
    Critical: "#E64D36",
  };
  let isAPIAwaiting = false;
  let responseData = null;
  let chartElement = null;
  let chart = null;
  let dataFound = true;
  let seriesValues = [];
  let Names = [];
  let Colors = [];
  $: endpoint = custom
    ? "parameters/api/SLAReport/custom"
    : "parameters/api/SLAReport";

  $: {
    if (
      baseURL &&
      nodeDetails &&
      startTime &&
      endTime &&
      currentScreen &&
      slaCategory &&
      (IncludeIgnored || !IncludeIgnored)
    )
      invokeAPI("POST", endpoint, onGetDataAPISuccess, `${currentScreen}/R`);
  }

  onMount(() => {
    if (responseData) drawPie();
  });

  const invokeAPI = function (
    method,
    endpoint,
    onsuccess,

    accessOrigin,
    extraHeader = {}
  ) {
    async function getResponse() {
      const companyId = localStorage.getItem("companyId");
      const applicationId = sessionStorage.getItem("appId");
      const accessToken = localStorage.getItem("access_token");
      if (!(accessToken && applicationId && companyId && accessOrigin)) return;
      let headers = {
        ...extraHeader,
        companyId: companyId,
        applicationId: applicationId,
        "access-origin": accessOrigin,
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };
      let payload = {
        EndTime: endTime,
        IncludeIgnored: IncludeIgnored,
        NodeId: nodeDetails.id,
        SLACategory: slaCategory,
        StartTime: startTime,
      };

      let options = { method, headers };
      if (payload) options.body = JSON.stringify(payload);
      isAPIAwaiting = true;
      clearChart();
      const response = await fetch(`${baseURL}/${endpoint}`, options);
      if (!response?.ok) {
        throw new Error(`HTTP error! status:${response?.status}`);
      }
      const data = await response.json();
      return data;
    }
    getResponse()
      .then((data) => {
        onsuccess && onsuccess(data);
      })
      .catch((error) => {
        isAPIAwaiting = false;
        console.log("API call failed", error);
      });
  };

  const onGetDataAPISuccess = function (data) {
    if (
      data?.Critical?.AlertRule &&
      data?.Informational?.AlertRule &&
      data?.Warning?.AlertRule
    ) {
      responseData = Object.entries(data).map(([key, value]) => {
        return { name: key, value };
      });
      isAPIAwaiting = false;
      dataFound = true;
      drawPie();
    } else {
      dataFound = false;
      isAPIAwaiting = false;
    }
  };

  function drawPie() {
    if (chartElement) {
      responseData?.sort((a, b) => {
        return sortOrder.indexOf(a.name) - sortOrder.indexOf(b.name);
      });

      let setRangeForNormal = function (value) {
        let min, max, temp;
        if (value == "Normal") {
          if (slaCategory == "Temperature") {
            temp = responseData?.find((item) => item.name === "Warning");
            min = temp.value.AlertRule?.Max;
            temp = responseData?.find((item) => item.name === "Informational");
            max = temp.value.AlertRule?.Min;
          } else if (slaCategory == "Humidity") {
            temp = responseData?.find((item) => item.name === "Warning");
            max = temp.value.AlertRule?.Min;
            temp = responseData?.find((item) => item.name === "Informational");
            min = temp.value.AlertRule?.Max;
          } else {
            temp = responseData?.find((item) => item.name === "Informational");
            min = temp.value.AlertRule?.Min;
            max = 0;
          }
        }
        return { min, max };
      };

      let setUnit = function () {
        if (slaCategory == "Temperature") return "°C";
        else if (slaCategory == "Humidity") return "%";
        else return "PPM";
      };

      Names = responseData?.map((item) => {
        let unit = setUnit();

        if (item?.name == "Critical")
          if (slaCategory == "CO2M")
            return `% of Total Time More Than ${item.value.AlertRule?.Value} ${unit} `;
          else
            return `% of Total Time Below ${item.value.AlertRule?.Min} ${unit} And Above ${item.value.AlertRule?.Max} ${unit}`;
        else if (item?.name == "Normal") {
          let data = setRangeForNormal(item.name);
          if (slaCategory == "CO2M") {
            return `% of Total Time Below ${data?.min} ${unit}`;
          } else {
            return `% of Total Time Between ${data.min} ${unit} - ${data.max} ${unit}`;
          }
        } else
          return `% of Total Time Between ${item.value.AlertRule?.Min} ${unit} - ${item.value.AlertRule?.Max} ${unit}`;
      });
      seriesValues = responseData?.map((item) => {
        return item.value?.PercentageValue;
      });
      Colors = responseData?.map((item) => {
        return selectColors[item.name];
      });

      if (chart) updateChart();
      else drawChart();
    } else {
      return;
    }
  }
  function drawChart() {
    let options = {
      horizontalAlign: "center",
      series: seriesValues,
      chart: {
        width: "350px",
        height: chartHeight,
        type: "pie",
      },
      dataLabels: {
        enabled: false,
      },
      labels: Names,
      colors: Colors,
      legend: {
        show: showLegend,
        horizontalAlign: legendAlignment,
        position: legendPosition,

        formatter: function (seriesName, opts) {
          const value =
            opts.w.globals.series[opts.seriesIndex] === -0
              ? Math.abs(opts.w.globals.series[opts.seriesIndex])
              : opts.w.globals.series[opts.seriesIndex];
          return [
            Number.isInteger(value) ? value : value.toFixed(2),
            seriesName,
          ];
        },
        fontFamily: "Helvetica, Arial",
        fontWeight: 400,
        fontSize: "12px",
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          const color = w.globals.fill.colors[seriesIndex];
          return `<div class="tooltip" style="background-color:${color};padding:2px 3px">${
            Names[seriesIndex]
          } : ${w.globals.series[seriesIndex] == 100 ? Math.abs(w.globals.series[seriesIndex]) : w.globals.series[seriesIndex].toFixed(2)}
          </div>`;
        },
        style: {
          fontSize: "12px",
        },
      },
    };

    chart = new ApexCharts(chartElement, options);
    chart.render();
  }
  function clearChart() {
    Names = [];
    Colors = [];
    seriesValues = [];
    if (chart) {
      updateChart();
    } else drawChart();
  }
  function updateChart() {
    if (chart) {
      chart.updateOptions({
        labels: Names,
        colors: Colors,
        series: seriesValues,
      });
    }
  }
</script>

<svelte:head>
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
  <link
    href="https://fonts.googleapis.com/css2?family=Roboto&display=swap"
    rel="stylesheet"
  />
</svelte:head>

<div class="del-pie-chart-container">
  {#if isAPIAwaiting}
    <Loader />
  {/if}
  <div class="del-pie-chart" bind:this={chartElement} />

  {#if !dataFound}
    <p class="not-configured">SLA settings not configured by the admin</p>
  {/if}
</div>

<style>
  .del-pie-chart-container {
    position: relative;
    max-width: 480px;
    height: auto;
    display: flex;
    flex-direction: column;
    font: normal normal bold 18px/24px Roboto;
  }
  .not-configured {
    position: absolute;
    top: 20%;
    color: #3e3e3e;
    width: 100%;
    text-align: center;
    height: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: italic;
    font-size: 12px;
    max-width: 100%;
    margin-bottom: 5px;
  }
  .del-pie-chart {
    max-width: 650px;
    margin: 15px auto;
    height: 300px;
  }
</style>
